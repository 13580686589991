.AccountCardContainer {
    display: grid;
    justify-content: space-between;
    gap: 15px;
}

.AccountCardField {
    display: flex;
    flex-direction: column;
}

.bank-details-heading {
    color: var(--color-dark);
    font-weight: bold;
}
@media only screen and (max-width: 576px) {
    .AccountCardContainer {
        font-size: 14px;
    }
}
