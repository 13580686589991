.lender-agreement-check{
 padding: 0.5rem 1rem;
 display: flex;
 align-items: center;

}
.agree_check_Box {
    height: 20px;
    width: 20px;
    border: 2px solid #ac172c;
    cursor: pointer;
    border-radius: 5px;
}

.CheckBoxActive {
    background-color: #ac172c;
}

.CheckBoxInactive {
    background-color: white;
}

.modal_btn_no {
    width: 10rem;
    height: 2.5rem;
    padding: 0.5em;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: no-drop;
}