.redBtn,
.whiteBtn,
.blueBtn {
    width: 45%;
    padding: 0.5em;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.redBtn {
    color: white;
    background-color: #b43b4c;
}

.whiteBtn {
    color: var(--color-dark);
    box-shadow: inset 0 0 0 1px var(--color-dark);
    background-color: white;
}

.blueBtn {
    color: white;
    background-color: #31364c;
}

.whiteBtn:hover {
    color: white;
    background-color: var(--color-dark);
}

.redBtn:hover {
    color: white;
    background-color: var(--color-accent);
}

.blueBtn:hover {
    background-color: var(--color-accent);
}

.nonBtnStyle {
    width: 100%;
    padding: 0.5em;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-dark);
    box-shadow: inset 0 0 0 1px var(--color-dark);
    background-color: white;
}
