.contact_text_input_container {
    padding: 0.4em;
}

.contact_text_label {
    font-size: 14px;
    margin: 0;
    color: var(--color-dark);
    padding-bottom: 2px;
}

.contact_text_input_field,
.contact_textarea_input_field {
    width: 95%;
    height: 40px;
    padding: 0.6em 0.8em;
    border-radius: 8px;
    outline: none;
    border: 1px solid var(--color-accent);
    background-color: var(--color-white);
}

.contact_text_input_field:focus,
.contact_textarea_input_field:focus {
    border: 3px solid var(--color-accent);
}

.contact_textarea_input_field {
    height: 14em;
    padding: 0.5em;
}

@media screen and (max-width: 1124px) {
    .contact_text_input_field,
    .contact_textarea_input_field {
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .contact_text_input_container {
        padding: 8px 0;
    }
}
