.Availability__Container {
    border-radius: 10px;
    border: 1px solid #c7c7c7;
    padding: 2em;
    width: 70%;
}

@media only screen and (max-width: 1400px) {
    .Availability__Container {
        margin: 11px auto;
    }
}

@media only screen and (max-width: 767px) {
    .Availability__Container {
        width: 95%;
    }
}

