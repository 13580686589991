.phone_container {
    width: 100%;
    padding: 2.5px 8px;
    border-radius: 10px;
    border: 1px solid var(--color-accent);
}

.phone_input_container {
    max-width: 250px !important;
    border: none !important;
    font-family: 'DMSans', sans-serif;
    font-size: 14px !important;
}

.LoginHeader--NoMargin-1 {
    color: var(--color-dark);
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}

.phone_btn_container {
    background: var(--color-white) !important;
    border: none !important;
}

.phone_dropdown_container {
    border-radius: 5px !important;
    border: 1px solid var(--color-accent);
}
