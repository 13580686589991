.ReviewModalFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 15%;
}

.ModalWrapperMain .ReviewModalFlex {
    position: static;
    width: 95%;
    max-width: 980px;
}

.ReviewModalMain {
    background-color: var(--color-white);
    color: var(--color-text);
    border: 2px solid var(--color-accent);
    border-radius: 15px;
    width: 100%;
    padding: 20px;
    min-height: 60vh;
    max-height: 90vh;
    position: relative;
}

.ReviewTitle {
    color: var(--color-dark);
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ReviewModalColumns {
    width: 100%;
    height: 90%;
    display: flex;
}

.MainLenderColumn {
    width: 40%;
    overflow-y: auto;
    padding: 0 20px 0 0;
    height: 100%;
}

.MainReviewColumn {
    width: 60%;
    overflow-y: scroll;
    padding: 0 0 0 20px;
    max-height: calc(100vh - 250px);
}

.ProfileIconReview {
    height: 75px !important;
    width: 75px !important;
}

.RatingHeaderReview {
    font-size: 30px;
    font-weight: bold;
}

.RatingLenderReview {
    font-size: 20px;
}

.StarsLenderReview {
    margin: 1em 0;
}

.StarsLenderReview .StarIcon {
    margin-right: 0.5em;
}

.StarsLenderReview .StarIcon:hover {
    cursor: auto;
}

.RatingLenderFlex .RatingHeader {
    font-size: 16px;
}

.RatingLenderFlex .RatingStarFlex {
    font-size: 18px;
}

.LenderItemsHeader {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-dark);
    margin-bottom: 0.5em;
}

.lander-items-list {
    max-height: calc(100vh - 420px);
    overflow: auto;
}

.LenderItemFlex {
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
}

.LenderItemImage {
    width: 75px;
    height: 75px;
    border-radius: 15px;
    overflow: hidden;
    text-align: center;
}

.LenderItemImage img {
    max-height: 75px;
    max-width: 75px;
    height: 100%;
    width: 100%;
}

.LenderItemName {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-text);
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.LenderRatingText {
    font-size: 13px;
    display: flex;
    align-items: center;
}

.LenderItemRatingFlex {
    display: flex;
    padding: 10px 0 10px 15px;
    flex-direction: column;
    width: calc(100% - 75px);
}

.LenderItemStars {
    margin-left: 0.5em;
}

.LenderItemStars .StarIcon {
    height: 13px;
    width: 13px;
}

.ReviewText {
    font-size: 18px;
}

.MainReviewColumn .hl {
    margin: 2em 0;
}

.MainLenderColumn .hl {
    margin: 1em 2em;
}

.CloseModalButton {
    border-radius: 23px;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.CloseModalButton:hover {
    cursor: pointer;
}

.ReviewModalClose {
    right: 5px;
    top: 5px;
    background-color: var(--color-accent);
    color: var(--color-white);
}

.ReviewModalReviewCard {
    margin-top: 1rem;
    padding-bottom: 1rem;
}

.ProductRatings,
.ItemLenderRating {
    display: flex;
    gap: 5px;
    font-size: 16px;
}

.ItemLenderRating {
    align-items: center;
}

@media screen and (max-width: 767px) {
    .ReviewModalColumns {
        flex-wrap: wrap;
    }

    .MainLenderColumn {
        width: 100%;
    }

    .MainReviewColumn {
        width: 100%;
        padding: 0;
    }

    .ReviewModalColumns .vl {
        display: none;
    }

    .LenderItemImage {
        width: 40px;
        height: 40px;
        border-radius: 8px;
    }

    .MainLenderColumn {
        padding: 0;
    }

    .LenderItemRatingFlex {
        padding: 0 0 0 10px;
        width: calc(100% - 40px);
    }

    .lander-items-list {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;
        margin-bottom: 10px
    }

    .ReviewTitle {
        font-size: 18px;
    }

    .RatingHeaderReview {
        font-size: 18px;
    }

    .lender-user-name {
        width: calc(100% - 45px);
    }

    .ProfileIconReview {
        height: 45px !important;
        width: 45px !important;
    }

    .LenderItemName {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .LenderItemFlex {
        margin: 0;
        margin-right: 10px;
        min-width: 180px;
    }
}