.AppicationModalCheck {
    background-color: var(--color-dark);
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
.ApplicationModalFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ApplicationModalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    user-select: none;
    display: flex;
    justify-content: center;
}
.ApplicationModalTitle {
    font-size: 25px;
    color: var(--color-dark);
    font-weight: bold;
}
.ApplicationModalText {
    font-size: 18px;
    text-align: center;
}
.ApplicationModalMain {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 15%;
    background-color: var(--color-white);
    color: var(--color-text);
    max-height: max-content;
    border: 2px solid #9e1e29;
    border-radius: 15px;
    width: 50%;
    height: max-content;
    max-width: 600px;
    padding: 3em 2em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ApplicationModalButton {
    background-color: var(--color-accent);
    width: 60%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}
.ApplicationModalPriceBlock {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.ApplicationModalTotalPrice {
    font-size: 16px;
    font-weight: bold;
}
.ApplicationModalButtonText {
    color: var(--color-white);
    font-size: 15px;
}
@media only screen and (max-width: 1400px) {
    .ApplicationModalTitle {
        font-size: 22px;
    }
    .ApplicationModalButton {
        min-height: 37px;
    }
}
@media only screen and (max-width: 495px) {
    .ApplicationModalMain {
        width: 90%;
        padding: 2em 1em;
    }
    .ApplicationModalTitle {
        font-size: 18px;
    }
    .ApplicationModalText {
        font-size: 14px;
    }
    .AppicationModalCheck {
        height: 35px;
        width: 35px;
        margin-bottom: 5px;
    }
    .ApplicationModalTotalPrice {
        font-size: 14px;
    }
}
