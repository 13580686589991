.ReviewCard,
.OnlyOneCard {
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    padding: 0.8em;
    max-height: 200px;
    overflow: auto;
}

.ReviewCard {
    width: 100%;
}

.OnlyOneCard {
    width: 96%;
}

.ReviewText {
    padding-top: 0.5em;
    font-size: 0.9em;
}

.ProfileIconSmall {
    height: 37px;
    width: 37px;
    margin: auto 0;
    border-radius: 50%;
    object-fit: cover;
}

.ReviewSmallText {
    font-size: 1.1em;
    margin-left: 0.5em;
}

.StarIconRatingSmall {
    height: 11px;
    margin-left: 0.5em;
}

.ReviewRatingTitle {
    display: flex;
    align-items: center;
}
