.NoReviewsContainer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.NoReviewsHeader {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--color-dark);
}

.NoReviewsText {
    margin-bottom: 2rem;
}
@media only screen and (max-width: 1400px) {
    .NoReviewsContainer {
        margin-top: 0.5rem;
    }
    .NoReviewsHeader {
        font-size: 20px;
        margin-bottom: 0.3rem;
    }
    .NoReviewsText {
        margin-bottom: 1rem;
    }
}
