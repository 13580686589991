.SearchButton {
    width: 35px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SearchButton:hover {
    box-shadow: none !important;
}

.SearchButtonFlex {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SearchButtonFlex img {
    width: 27px;
    height: 27px;
    transition: all 250ms ease-in-out;
}

.SearchButtonFlex:hover img {
    transform: scale(1.1);
}
