.Banner {
    border-bottom: 2px solid var(--color-accent);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    text-align: center;
}

.BannerText {
    font-size: 25px;
    font-weight: bold;
}

.BannerTextNormal {
    font-weight: normal;
}

.BannerBackBtn {
    position: relative;
    width: 35px;
    height: 35px;
    margin-right: 1em;
    transform: translateX(0);
    transition: transform 0.5s;
    cursor: pointer;
}

.BannerBackBtn:hover {
    transform: translateX(-15px);
}
@media only screen and (max-width: 1400px) {
    .Banner {
        padding: 0.5em 0;
    }
    .BannerText {
        font-size: 20px;
    }
    .BannerTextNormal {
        font-size: 18px;
    }
}
@media only screen and (max-width: 576px) {
    .BannerText {
        font-size: 16px;
    }
    .BannerTextNormal {
        font-size: 14px;
    }
}
