.ModalWrapperMain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AvailabilityModalMain {
    position: relative;
    width: 550px;
    margin: auto;
    max-height: 500px;
    background-color: var(--color-white);
    color: var(--color-text);
    overflow-y: auto;
    border-radius: 24px;
    padding: 50px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AvailabilityModalContainer {
    overflow: auto;
    width: 100%;
}
@media only screen and (max-width: 1400px) {
    .AvailabilityModalMain {
        width: 410px;
        height: 400px;
        padding: 50px 12px 0;
    }
    .AvailabilityTitle {
        font-size: 18px;
    }
    .AvailabilityCloseBtn {
        top: -12px;
        right: 9px;
        width: 30px;
        height: 30px;
    }
}
@media only screen and (max-width: 495px) {
    .AvailabilityModalMain {
        width: 98%;
        padding: 0 0.2em;
    }
}

.AvailabilityTitleContainer {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.AvailabilityTitle {
    font-size: 1.2em;
}

.AvailabilityCloseBtn {
    position: absolute;
    top: -5px;
    right: 7px;
    border-radius: 23px;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b43b4c;
    color: var(--color-white);
    cursor: pointer;
}

@media only screen and (max-width: 495px) {
    .AvailabilityCloseBtn {
        top: -13px;
        width: 29px;
        height: 29px;
    }
}
