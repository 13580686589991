.UpdatePassword__Body {
    font-size: 18px;
    margin: 0.5em 0;
}

.UpdatePassword__Header {
    margin-top: 1em;
}

.UpdatePassword__Body p {
    margin-top: 0;
}

.UpdatePassword__SuccessPopup {
    border: 1px solid var(--color-accent);
    border-radius: 10px;
    padding: 1em;
    background-color: var(--color-white);
    position: absolute;
    bottom: 20px;
    max-width: 70%;
}

@media screen and (max-width: 1400px) {
    .UpdatePassword__Body {
        font-size: 14px;
        margin: 0.5em 0;
    }
}
