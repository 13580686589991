.BorrowerHeaderContent {
    display: flex;
    margin-top: -2%;
    width: 100%;
}

.BorrowerHeader {
    color: var(--color-dark);
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 0.5em;
    display: flex;
    margin-top: 0.5em;
    align-items: center;
}

.CloseButton {
    background-color: #ac172c;
    border: none;
    outline: none;
    border-radius: 50%;
    padding: 19px;
    font-family: 'DMSans', sans-serif;
    color: var(--color-white);
    font-size: 24px;
    height: 35px;
    width: 35px;
    justify-content: center;
}
.CloseIcon {
    height: 16px;
}

.CloseButtonFlex {
    display: flex;
    align-items: center;
}

.BorrowerText {
    padding-bottom: 1em;
    font-size: 18px;
    width: 100%;
    margin-top: 5%;
    text-align: justify;
}

.BorrowerStarIcon {
    height: 25px;
    width: 25px;
    min-width: 25px;
    padding-right: 7px;
}

.BorrowerStarsFlex {
    display: flex;
    width: 100%;
    /* margin-top: -20px; */
    margin-bottom: 10px;
}

.BorrowerRatingText {
    color: #c7c7c7;
}

.CheckboxFlex {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: -1%;
    font-size: 15px;
    width: 100%;
}
