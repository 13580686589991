.ItemImageModalContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    user-select: none;
}

.CarouselModalFlexContainer {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 10%;
}

.download-button{
    position: absolute;
    height: 40px;
    width: 40px;
    top: 15px;
    right: 20px;
    background-color: antiquewhite;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
.ItemImageModalCloseBtn {
    position: absolute;
    top: -8%;
    left: 50px;
    border-radius: 23px;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    color: #b43b4c;
}

.ImageModal {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin: 2em 0;
}

.ImageModalImage {
    width: 600px;
    height: 500px;
    border-radius: 20px;
    object-fit: contain;
    margin: 0 0.5em;
    background: #181818;
}

.OffScreenForward {
    position: absolute;
    top: 0;
    left: 90%;
}

.OffScreenBackward {
    position: absolute;
    top: 0;
    right: 90%;
}

.OffScreenBackward:hover,
.OffScreenForward:hover {
    cursor: pointer;
}

.ImageNumberDisplay {
    position: absolute;
    border-radius: 34px;
    color: var(--color-white);
    font-size: 1.2em;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.5em 2em;
    top: 80%;
}

.ImageModalCarousel {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    align-items: center;
}

.ImageSmallContainer {
    width: 10em;
    height: 10em;
    position: relative;
    margin: 0 1em;
    border-radius: 20px;
    display: flex;
    background: #181818;
}

.ImageModalSmall {
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
    display: block;
}

.SmallImageInactive {
    position: absolute;
    top: 0;
    background-color: rgba(20, 25, 31, 0.5);
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.SmallImageActive {
    border: 2px solid var(--color-white);
}

.SmallImageInactive:hover {
    cursor: pointer;
}

.HideOverlay {
    display: none;
}

@media only screen and (max-width: 800px) {
    .ItemImageModalCloseBtn {
        top: -10%;
    }
    .ImageModalImage {
        width: 400px;
        height: 300px;
    }
    .ImageNumberDisplay {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 600px) {
    .ImageModalImage {
        width: 100%;
        max-height: 600px;
        height: auto;
        margin: 0;
        z-index: 200;
        border-radius: 0;
    }
}
