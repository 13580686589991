.CategoryDDIcon {
    max-height: 30px;
    margin-bottom: 5px;
}

.ReactIconCategory {
    width: 22px;
    height: 22px;
    max-width: 20px;
    color: #b03b4b;
}

.CatIconContainer {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 32px;
}

.SelectInput .CatIconContainer {
    display: none;
}

.DropDownTitle {
    padding: 8px 8px;
    font-size: 16px;
    font-weight: 600;
    color: #ab3e53;
    margin-top: 15px;
    margin-left: 8px;
}

@media only screen and (max-width: 1400px) {
    .CatIconContainer {
        min-height: 22px;
    }
}
