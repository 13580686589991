.RegistrationWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.LoginMainNoMarg {
    margin: 0;
    margin-bottom: 2em;
}

.ExpiryCCVFlex {
    display: flex;
    width: 100%;
    gap: 25px;
}

.TCScrollDiv {
    max-width: 400px;
    width: 100%;
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid var(--color-accent);
    border-radius: 10px;
    padding: 0.5em;
}

.TCCheckboxFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
    font-size: 15px;
}

.TCCheckbox {
    margin-right: 1em;
}

.ProfilePictureFlex {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;
}

.ProfilePictureCircle {
    border: 1px solid var(--color-accent);
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2em;
    overflow: hidden;
}

.ProfilePicturePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.UploadButton {
    width: 50%;
}

.CameraIcon {
    height: 35px;
}

.PasswordInputContainer {
    width: 100%;
    position: relative;
}

.ShowPasswordIcon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.ShowPasswordIcon:hover {
    cursor: pointer;
}

.BecomeLenderFlex {
    display: flex;
    width: 100%;
    align-items: center;
}

.LenderSwitchInfoFlex {
    display: flex;
    margin-left: 2em;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}

.SkipNextButtonFlex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ButtonDisabled {
    background-color: rgba(172, 23, 44, 0.5);
}

.ButtonDisabled:hover {
    cursor: auto;
}

.ValidationText {
    color: var(--color-accent);
    font-size: 14px;
    width: 100%;
    margin-bottom: 1em;
}

.ValidationTextShow {
    opacity: 100% !important;
}

.LoginInputValidationContainer {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
}
.phonenumber-note{
    font-size: 12px;
    /* color: var(--color-dark); */
}
.back-arrow {
    transform: rotate(180deg);
    width: 40px;
    height: 40px;
}
.Register__DOB__Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.DOBInput {
    width: 30%;
    padding: 0.7em;
    border-radius: 10px;
    border: 1px solid var(--color-accent);
    margin-bottom: 1em;
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.DOBHeader {
    width: 30%;
    margin-bottom: 0.5em;
    margin-left: 0.5em;
    color: var(--color-dark);
    font-size: 14px;
    font-weight: bold;
}
.otpError {
    width: 100%;
    margin-bottom: 0.5em;
    margin-left: 0.5em;
    color: var(--color-dark);
    font-size: 14px;
    color: #ac172c;
}

@media only screen and (max-width: 1400px) {
    .LoginInputValidationContainer {
        margin-bottom: 8px;
    }
}

@media only screen and (max-width: 576px) {
    .TCCheckboxFlex {
        margin: 0.5em 0;
        font-size: 14px;
    }
}
