.ExtensionModalCheck {
    background-color: var(--color-dark);
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
.ExtensionModalFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ExtensionModalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    user-select: none;
    display: flex;
    justify-content: center;
}
.ExtensionModalTitle {
    font-size: 25px;
    color: var(--color-dark);
    font-weight: bold;
}
.ExtensionModalText {
    padding-top: 10;
    font-size: 18px;
    text-align: center;
}
.ExtensionModalMain {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 15%;
    background-color: var(--color-white);
    color: #000;
    max-height: max-content;
    border: 2px solid #9e1e29;
    border-radius: 15px;
    width: 50%;
    height: max-content;
    max-width: 600px;
    padding: 3em 2em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ExtensionModalButton {
    background-color: #9e1e29;
    width: 60%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}
.ExtensionModalButtonText {
    color: white;
    font-size: 15px;
}

@media only screen and (max-width: 495px) {
    .ExtensionModalMain {
        width: 90%;
        padding: 2em 1em;
    }
}
