.InLineErrorContainer {
    background-color: var(--color-light);
    border-radius: 10px;
    padding: 0.7em;
    margin-top: 0.2em;
    font-size: 18px;
    user-select: none;
}
.InLineErrorContainer_support {
    background-color: var(--color-light);
    border-radius: 10px;
    padding: 0.7em;
    margin-top: 0.2em;
    font-size: 18px;
    user-select: none;
    margin-bottom: 1.2em;
}

.ValidationInputContainer {
    margin-bottom: -2px;
    width: 100%;
}

.ValidationInput {
    width: 100%;
    padding: 8px 15px;
    border-radius: 8px;
    border: 1px solid var(--color-accent);
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-size: 16px;
    height: 42px;
}

.ValidationInput:disabled {
    border: 1px solid #dfc5c5 !important;
}
.PasswordInput {
    width: 100%;
    border-radius: 10px;
    border-width: 0;
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.react-tel-input .form-control {
    line-height: 20px;
    height: 25px;
}

.ValidationInputErrorContainer {
    background-color: var(--color-light);
    border-radius: 10px;
}

.PasswordInputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.4em;
    border-radius: 10px;
    border: 1px solid var(--color-accent);
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.IdProvideInputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;
}

.IdProvideImageSquare {
    border: 1px solid var(--color-accent);
    height: 120px;
    width: 120px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2em;
    overflow: hidden;
}

.IdPicturePreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.BankDetailMainTitle,
.BankDetailSubTitle,
.IdProviderMainTitle,
.IdProviderSubTitle {
    margin: 0 0 5px;
    width: 100%;
}

.IdProviderMainTitle,
.BankDetailMainTitle {
    color: var(--color-dark);
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin: 0 0 5px;
}

.red_bg {
    background-color: var(--color-light);
    border-radius: 10px;
}
@media only screen and (max-width: 1400px) {
    .ValidationInputContainer {
        margin-bottom: 0.5em;
    }
    .ValidationInput {
        font-size: 14px;
        height: 40px;
    }
    .PasswordInputContainer {
        padding: 0.4em;
        border-radius: 8px;
        font-size: 16px;
    }
    .PasswordInput {
        font-weight: normal;
        font-size: 16px;
    }
    .InLineErrorContainer {
        font-size: 12px;
    }

    .MuiInputBase-root.SelectInput.SelectInput--Thin.makeStyles-select-4.makeStyles-select-21 {
        padding: 4px !important;
        border-radius: 8px !important;
    }
}
