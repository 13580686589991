.RatingStars {
    display: flex;
    justify-content: center;
    align-items: center;
}

.StarIcon {
    height: 18px;
    width: 18px;
    padding: 0 1px;
}
