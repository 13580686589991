.Support_Container {
    width: 60%;
    height: 100%;
    padding: 2em;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    margin: 0 auto;
}

.Support_Header {
    color: var(--color-dark);
    font-size: 1.5em;
    font-weight: bold;
    width: 100%;
    margin-bottom: 0.5em;
}

.Suppor_Description {
    padding-bottom: 1em;
    font-size: 18px;
    width: 100%;
}

.upload_attachment {
    cursor: pointer;
    border: 1px solid var(--color-accent);
    width: 100%;
    border-radius: 10px;
    padding: 1em;
    font-size: 1.1em;
    outline: none;
    margin-bottom: 1em;
    font-family: 'DMSans', sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
}

.upload-icon {
    color: solid var(--color-accent);
    margin-right: 10px;
}
.Support_Input_Field {
    width: 100%;
    border: 1px solid var(--color-accent);
    border-radius: 10px;
    padding: 1em;
    font-size: 1.1em;
    outline: none;
    margin-bottom: 1em;
    font-family: 'DMSans', sans-serif;
}

.Support_Textarea {
    width: 100%;
    border: 1px solid var(--color-accent);
    border-radius: 10px;
    padding: 0.7em;
    font-size: 1.1em;
    outline: none;
    font-family: 'DMSans', sans-serif;
    margin-bottom: 1em;
}

.support_attachment_list {
    display: flex;
    align-items: center;
    gap: 20px;
    overflow-x: auto;
    padding: 15px 0 0;
    margin-bottom: 15px;
}

.loader-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 110px;
}

.upload_item_image_box.support-list {
    width: 110px;
    flex: 0 0 110px;
    height: 110px;
}

.support-list video {
    height: 110px;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--color-accent);
    object-fit: cover;
}

@media only screen and (max-width: 1400px) {
    .Support_Container {
        padding: 1.5em;
        height: auto;
    }
    .Support_Header {
        font-size: 18px;
    }
    .Suppor_Description {
        font-size: 15px;
    }
    textarea.Support_Textarea {
        font-size: 14px;
    }
    .Support_Input_Field {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .Support_Container {
        width: 95%;
    }
}
