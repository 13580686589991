.Button {
    padding: 12px 16px;
    width: 100%;
    background-color: var(--color-accent);
    font-size: 16px;
    color: var(--color-white);
    font-weight: bold;
    border-radius: 8px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ButtonInverted {
    padding: 0.8em;
    width: 100%;
    background-color: var(--color-white);
    font-size: 15px;
    color: var(--color-accent);
    font-weight: bold;
    border-radius: 10px;
    border: 1px solid var(--color-accent);
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.2em;
}
.ButtonErrorContainer {
    background-color: var(--color-white);
    border-radius: 10px;
}

.ButtonContainer {
    width: 100%;
}

.ButtonValidationContainer {
    position: relative;
    width: 100%;
}
@media only screen and (max-width: 1400px) {
    .Button {
        padding: 9px 16px;
        font-size: 14px;
        font-weight: 500;
    }
    .ButtonInverted {
        padding: 9px 16px;
        font-size: 14px;
        font-weight: 500;
        min-height: 32px;
    }
}
