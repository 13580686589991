html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

@import url('https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-dark: #33384f;
    --color-bg-light: #e9d8b4;
    --color-light: #f7e7e9;
    --color-white: #fff;
    --color-grey: #858585;
    --color-light-grey: #f5f5f5;
    --color-text: #000;
    --color-accent: #ac172c;
}

body {
    position: relative;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
}

.container {
    padding: 0 12px;
    margin: 0 auto;
}

@media screen and (min-width: 767px) {
    .container {
        max-width: 720px;
    }
}

@media screen and (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media screen and (min-width: 1400px) {
    .container {
        max-width: 1120px;
    }
}

/* Font imports */

@font-face {
    font-family: 'DMSans';
    src: url(/src/assets/Fonts/DMSans-Regular.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DMSans';
    src: url(/src/assets/Fonts/DMSans-Bold.ttf);
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DMSans';
    src: url(/src/assets/Fonts/DMSans-BoldItalic.ttf);
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DMSans';
    src: url(/src/assets/Fonts/DMSans-Italic.ttf);
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DMSans';
    src: url(/src/assets/Fonts/DMSans-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DMSans';
    src: url(/src/assets/Fonts/DMSans-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Kameron';
    src: url(/src/assets/Fonts/Kameron-Regular.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kameron';
    src: url(/src/assets/Fonts/Kameron-Bold.ttf);
    font-weight: bold;
    font-style: italic;
}

html,
body {
    margin: 0;
    font-family: 'DMSans', sans-serif;
    overflow-x: hidden;
}

.MuiCircularProgress-colorSecondary {
    color: #ac172c !important;
}

p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

button {
    outline: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

button:hover {
    cursor: pointer;
    box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 2px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
}

a {
    color: inherit;
    text-decoration: inherit;
}

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c2c9d2;
}

.toaster_container {
    /* background-color: var(--color-light); */
    color: var(--color-dark);
    width: fit-content;
    max-width: 400px;
    border-radius: 10px;
    padding: 0.5em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.toaster-container {
    min-width: 450px;
}

.toaster-container>div {
    border-left: 8px solid rgb(244, 248, 31);
    padding: 0;
    /* background: #f7e7e9; */
    border-radius: 10px;
}

.notification-title {
    font-size: 22px;
    margin: 0 0 5px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ItemInfoWrapper .ItemButtons button {
    width: 45%;
    margin: 0 0 12px;
}

.cus-modal .MuiPaper-rounded {
    border-radius: 15px;
}

.notification-body {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 20px;
    max-height: 40px;
}

.notification__Icon {
    margin-right: 10px;
    min-width: 45px;
    width: 45px;
    font-size: 30px;
}

.not-rating-yet {
    color: #adadad;
    font-size: 16px;
}

.AccountSettings__SavedCard {
    background: url(./assets/Images/atmbg.png);
}

.web-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 30px 2vw;
}

.btn-primary {
    background: #ac172c !important;
    color: #fff !important;
    padding: 9px 30px;
    border-radius: 8px;
    font-weight: 600;
}

.btn-primary:hover {
    background: #33384f !important;
    color: #fff !important;
}

section.profile-sidebar {
    width: 300px;
    flex: 0 0 300px;
    padding: 12px;
    border: 1px solid #efefef;
    border-radius: 20px;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    text-align: center;
}

section.profile-right-sidebar {
    width: calc(100% - 325px);
    flex: 0 0 calc(100% - 325px);
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1400px) {
    .not-rating-yet {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .test .ItemButtons {
        gap: 15px;
    }

    .ItemButtons {
        flex-wrap: wrap;
        gap: 0;
    }

    .ItemInfoWrapper .ItemButtons button {
        width: 100%;
        margin: 0 0 12px;
    }

    .MuiButtonBase-root.MuiPaginationItem-root {
        width: 25px;
        min-width: 25px;
        height: 24px;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 14px;
    }
}

@media only screen and (max-width: 575px) {
    .test .ItemButtons {
        flex-direction: row !important;
        justify-content: center;
        align-items: center;

    }

    .test .ItemButtons .ButtonAvailability {
        width: auto;
        margin-bottom: 0;
    }
}

.makeStyles-select-2 .MuiSvgIcon-root {
    margin-right: 0 !important;
}