.BorrowerHeaderContent {
    display: flex;
    margin-top: -2%;
    width: 100%;
}

.BorrowerMain {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 30px !important;
    width: 100%;
    min-width: 550px;
    margin: 0;
    background-color: var(--color-white);
}

.BorrowerHeader {
    color: var(--color-dark);
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 0.5em;
    display: flex;
    margin-top: 0.5em;
    align-items: center;
}

.CloseButton {
    background-color: var(--color-accent);
    border: none;
    outline: none;
    border-radius: 50%;
    padding: 19px;
    font-family: 'DMSans', sans-serif;
    color: var(--color-white);
    font-size: 24px;
    height: 35px;
    width: 35px;
    justify-content: center;
}
.CloseIcon {
    height: 16px;
}

.CloseButtonFlex {
    display: flex;
    align-items: center;
}

.BorrowerText {
    padding-bottom: 1em;
    font-size: 18px;
    width: 100%;
    margin-top: 5%;
    text-align: justify;
}

.BorrowerStarIcon {
    height: 25px;
    width: 25px;
    min-width: 25px;
    padding-right: 7px;
}

.BorrowerStarsFlex {
    display: flex;
    width: 100%;
    /* margin-top: -20px; */
    margin-bottom: 10px;
}

.BorrowerRatingText {
    color: #c7c7c7;
}
@media only screen and (max-width: 1400px) {
    .BorrowerMain {
        padding: 20px !important;
        min-width: 460px;
    }
    .BorrowerHeader {
        font-size: 16px;
    }
    .BorrowerStarsFlex {
        /* margin-top: -10px; */
        margin-bottom: 5px;
    }
    .BorrowerStarIcon {
        height: 25px;
        width: 25px;
        min-width: 25px;
        padding-right: 7px;
    }
    .BorrowerRatingText {
        font-size: 14px;
    }
}
@media only screen and (max-width: 576px) {
    .BorrowerMain {
        min-width: 296px;
    }
}
