.ItemMainWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 4em;
}

.ItemInfoWrapper {
    width: 48.5%;
    font-family: 'DMSans', sans-serif;
}

.ItemPicturesWrapper {
    width: 48.5%;
}

.ItemName {
    text-transform: capitalize;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}

.ItemPriceFlex {
    display: flex;
    margin-bottom: 5px;
}

.ItemRateDiscountFlex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0.5em;
    padding-top: 0.5em;
}

.ItemPriceTextBig {
    font-size: 42px;
    color: var(--color-dark);
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
}

.ItemRateTextBig {
    font-size: 34px;
    color: #58554e;
}

.ItemDiscountText {
    font-size: 18px;
    color: var(--color-accent);
    margin-bottom: 15px;
}

.LocationDeliveryCategory {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    gap: 12px;
    flex-wrap: wrap;
}

.LDCIconContainer {
    display: flex;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    background: #ededed;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.LDCIcon {
    max-width: 100%;
    max-height: 100%;
}

.DeliveryFeeText {
    color: #a60031;
}

.ItemButtons {
    display: flex;
    margin-bottom: 0;
    width: 100%;
    gap: 12px;
}

.ItemButtons button {
    padding: 10px 18px;
    border-radius: 8px;
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}

.ItemButtonFlex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.ChatStartButtonFlex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 8%;
    align-items: center;
}

.ItemButtons img {
    height: 30px;
}

.ButtonAvailability {
    border: 1px solid var(--color-dark);
    background-color: var(--color-white);
    min-width: 120px;
}

.ButtonApply {
    background-color: var(--color-dark);
    border: 1px solid var(--color-dark);
    color: var(--color-white);
    min-width: 120px;
}
.ButtonApply:disabled {
    background-color: #a2a5b4;
    border: none;
}

.ButtonColdChat {
    background-color: var(--color-accent);
    border: 1px solid var(--color-accent);
    color: var(--color-white);
    width: 80%;
}

.ButtonColdChat:disabled {
    background-color: #ebcaca;
    border: none;
}

.ButtonColdChat:hover {
    box-shadow: none;
}

.ButtonFavourite {
    width: 20%;
    background-color: var(--color-accent);
    border: 1px solid var(--color-accent);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ButtonFavourite:disabled {
    background-color: #ebcaca;
    border: none;
}

.ButtonFavourite:hover {
    box-shadow: none;
}

.hr {
    border: 1px solid #ebebeb;
    margin: 35px 0;
}

.ItemDetailsHeader {
    font-weight: bold;
    font-size: 18px;
    color: var(--color-dark);
    margin-bottom: 12px;
}

.description-box {
    border-top: 2px solid #e6e6e6;
    padding: 15px 0;
    margin: 20px 0 0;
    font-size: 16px;
}
.ItemDetailsHeader{
    border-top: 2px solid #e6e6e6;
    padding: 15px 0;
    margin: 20px 0 0;
    font-size: 16px;
}
.ItemSpecificationFlex{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    width: 80%;
    margin-bottom: 1em;
}
.ItemSpecificationFlex p {
    font-weight: normal;
}
.ItemDetailsFlex {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    width: 80%;
    margin-bottom: 1em;
    font-weight: bold;
}

.ItemDetailsFlex div {
    font-weight: normal;
}

.ProductRatingRow {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    width: 100%;
}

.ProductRatingContainer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    width: 100%;
}

@media only screen and (max-width: 1400px) {
    .ProductRatingContainer {
        flex-direction: column;
        justify-content: center;
    }

    .ItemName {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .ItemPicturesWrapper {
        width: 38.5%;
    }

    .description-box {
        padding: 10px 0;
        margin: 15px 0 0;
    }

    .ItemMainWrapper {
        justify-content: space-evenly;
        margin-top: 2em;
    }

    .ItemInfoWrapper {
        width: 42.5%;
    }

    .ItemPriceTextBig {
        font-size: 22px;
    }

    .ItemButtons img {
        height: 23px;
    }

    .ItemButtons button {
        padding: 6px 10px;
        font-size: 14px;
    }

    .LDCIconContainer {
        width: 35px;
        height: 35px;
        flex: 0 0 35px;
    }

    .LocationDeliveryCategory {
        margin-bottom: 12px;
    }

    .RatingDetailContainer {
        margin-left: 1.5em;
    }
}

.RatingHeader {
    font-weight: bold;
}

.RatingStarFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.StarIconRating {
    height: 18px;
    margin-left: 0.5em;
}

.RatingLenderFlex {
    display: flex;
    margin-bottom: 10px;
}

.lender-user-name {
    width: calc(100% - 75px);
    padding-left: 15px;
}

.ProfileIcon {
    height: 50px;
    width: 50px;
    margin: auto 0;
}

.ProductIcon {
    height: 50px;
    width: 50px;
    margin: auto 0;
    border-radius: 50%;
    object-fit: cover;
}

.RatingDetailContainer {
    margin-left: 0.5em;
}

.ReviewCardSection {
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
}

.ViewReviewsButton {
    width: 100%;
    border: 1px solid #b03b4b;
    padding: 1em;
    text-align: center;
    font-size: 14px;
    border-radius: 10px;
    background-color: var(--color-white);
    font-family: 'DMSans', sans-serif;
}

.ReviewCarousel {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.ReviewButtonFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.ReviewButtonFlex:hover {
    cursor: pointer;
}

.ReviewCarousel div {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin: 0 0.2em;
    color: var(--color-white);
}

.ReviewPageInactive {
    border: 1px solid var(--color-accent);
}

.ReviewPageActive {
    background-color: var(--color-accent);
}

.ItemPicturesWrapper .image {
    background: #fff;
    border-radius: 22px;
    margin-bottom: 1em;
    cursor: pointer;
    text-align: center;
}

.ItemPicturesWrapper .image>img {
    max-width: 100%;
    object-fit: contain;
    max-height: 24em;
    margin: auto;
    width: 100%;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 20px 20px 0 0;
    margin-bottom: 22px;
    cursor: pointer;
    box-shadow: 0 2px 30px rgb(0 0 0 / 10%);
}

.ItemImageSecondary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.SecondaryItemImageContainer {
    position: relative;
    width: 48%;
    height: 12em;
    cursor: pointer;
}

.SecondaryItemImage {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
}

.ImageModalButton {
    width: 45%;
    color: var(--color-white);
    border-radius: 10px;
    background-color: var(--color-accent);
    padding: 1em;
    border: none;
    outline: none;
    font-family: 'DMSans', sans-serif;
}

.NavyOverlay {
    position: absolute;
    background-color: rgba(20, 25, 31, 0.5);
    border-radius: 0 0 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
}

.NavyOverlayMain {
    border-radius: 30px;
}

.PickupLocationText {
    font-size: 18px;
    font-weight: bold;
    margin: 0.5em 0;
}

.PickupLocationTextLight {
    font-size: 18px;
    color: #c7c7c7c7;
    margin-bottom: 1em;
}

.MapContainer {
    height: 400px;
    width: 100%;
}

.Hide {
    visibility: hidden;
}

.marker {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background: '#B03B4B';
}

.editButton {
    width: 100%;
    background-color: var(--color-dark);
    border: none;
    color: var(--color-white);
    font-size: 18px;
    padding: 0.5em;
    border-radius: 10px;
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-size: 18px;
}

.ItemPage__Loading__Container {
    margin: 5em 0;
}

.ItemPerSlotText {
    color: #58554e;
    font-size: 14px;
    margin: 0 0 5px 3px;
}

@media only screen and (max-width: 1400px) {
    .editButton {
        font-size: 14px;
    }
}

@media only screen and (max-width: 991px) {
    .ItemMainWrapper {
        width: 100%;
        padding: 0 15px;
        flex-wrap: wrap;
    }

    .ItemPicturesWrapper {
        width: 100%;
        order: 1;
    }

    .ItemInfoWrapper {
        width: 100%;
        order: 2;
    }

    .ItemButtonFlex svg {
        width: 24px;
        height: 24px;
    }

    .ItemButtons img {
        width: 24px;
        height: 24px;
    }

    .ItemButtons button {
        padding: 8px 10px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .ItemButtons {
        gap: 0;
    }

    .ItemPriceTextBig {
        font-size: 36px;
    }
}

@media only screen and (max-width: 575px) {
    .ItemPriceTextBig {
        font-size: 24px;
    }

    .ItemPerSlotText {
        font-size: 12px;
    }

    .ItemName {
        font-size: 26px;
    }

    .ItemMainWrapper {
        width: 100%;
        align-items: center;
        margin-top: 20px;
        padding: 0 0;
    }

    .ItemPicturesWrapper .image>img,
    .ItemPicturesWrapper .image {
        margin-bottom: 0;
    }

    .ItemPicturesWrapper {
        width: 100%;
    }

    .ItemInfoWrapper {
        width: 100%;
    }

    .ItemButtons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ButtonApply {
        width: 100%;
        margin-bottom: 1rem;
    }

    .ButtonFavourite {
        width: 100%;
        align-self: center;
    }

    .ButtonAvailability {
        width: 100%;
        margin-bottom: 1rem;
    }

    .ProductRatingRow {
        width: 100%;
    }

    .ItemName {
        text-align: center;
    }
}