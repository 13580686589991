.CategoryDDIcon {
    max-height: 30px;
    margin-bottom: 5px;
}

.SelectInput .CatIconContainer {
    display: none;
}

.MuiSelect-root .StarIcon {
    display: none;
}

li .RatingText {
    display: none;
}
