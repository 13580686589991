.UserCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 15px;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;
    min-height: 80px;
}

.UserCard:hover {
    cursor: pointer;
}

.UserCardDetails {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.NewMessageNotification {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color-accent);
    margin-left: 1rem;
}

.UserCardTop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    position: relative;
}

.UserCardIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 2%;
    padding-right: 2%;
}

.UserCardContent {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
}

.UserCardBlockButton {
    padding: 0.5rem 1rem;
}

.UserCardBlockButton:hover {
    cursor: pointer;
    text-decoration: underline;
}

.UserCardDotIconContainer {
    margin-bottom: 0.5em;
}

.LastMessageOwner {
    font-weight: bold;
}

.chat-head-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background: #ededed;
    padding: 15px;
    border-radius: 10px;
}

.btn-blue-bg {
    padding: 5px 20px;
    color: #fff;
    background: #33384f;
    border: 1px solid #33384f;
    border-radius: 10px;
    width: calc(50% - 8px);
}

.btn-blue-border {
    padding: 5px 20px;
    background: #fff;
    color: #33384f;
    border: 1px solid #33384f;
    border-radius: 10px;
    width: calc(50% - 8px);
}

.UserCardBottom div {
    font-weight: 200;
    font-size: 13px;
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.unread-message div {
    color: var(--color-accent) !important;
    font-weight: 600 !important;
}
.UserCardDetails h6 {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
}

.userstatus {
    background: var(--color-accent) !important;;
    color: #fff;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    border: 3px solid #fff;
    z-index: 6;
    display: flex;
    justify-content: center;
}

/* .unread-status {
  background: #e04102;
  border: 3px;
  border-radius: 26px;
  height: 15px;
  width: 15px;
  margin-left: 10px;
}
.user-name {
  display: flex;
  align-items: center;
} */
.userstatus.active {
    background: #33384f;
}

.UserCardDetails span {
    font-size: 12px;
}
@media only screen and (max-width: 1400px) {
    .UserCardBottom {
        font-size: 12px;
    }
    .UserCardDetails h6 {
        font-size: 16px;
    }
    .UserCardBlockButton {
        padding: 0.4rem 0.8rem;
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .MessagesHeading,
    .UserCard {
        padding: 0 15px 15px;
        margin-bottom: 0;
    }
}