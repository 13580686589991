.TextInput {
    border-radius: 10px;
    height: 48px;
    border: 1px solid #95272f;
    outline: none;
    padding: 10px 18px;
    width: 100%;
    font-family: 'DMSans', sans-serif;
    background-color: var(--color-white);
    font-size: 16px !important;
}

.HeaderBarSticky .TextInput {
    max-height: 48px;
    outline: none;
    padding: 8px 15px;
}

@media only screen and (max-width: 1400px) {
    .TextInput {
        max-height: 48px;
    }
}
