.ItemCard {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 0 12px 24px;
    position: relative;
    display: inline-block;
}

.product-list {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 5px rgb(0 0 0 / 8%);
    height: 100%;
}

.product-list a {
    display: block;
}

.price-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.ItemNameText {
    color: var(--color-text);
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 8px;
}

.ItemPriceText {
    font-size: 20px;
    color: #a60031;
    font-weight: bold;
}

.ItemRateText {
    font-size: 16px;
    color: #adadad;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin: 3px 0 0;
}

.StatusLocationSection {
    display: flex;
    font-size: 16px;
    align-items: center;
    margin-bottom: 8px;
    gap: 8px;
    width: 100%;
}


.overlay-pause {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center;
    font-size: 40px;
    font-weight: bold;
    color: #FFF;
    background-color: #3f3d3d;
    opacity: 0.7; /* Adjust opacity as needed */
}
.ItemCardIcon {
    height: 25px;
    width: 25px;
    flex: 0 0 25px;
}

.pause-icon {
    display: flex;
    padding: 5px;
    background-color: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 10px;
    left: 30px;
    right: 0;
    bottom: 0;
    cursor: pointer;
}
.ItemCardIconContainer {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    display: flex;
    align-items: center;
}

.RatingSection {
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 5px;
}

.PreviewImage {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    min-height: 230px;
    max-height: 230px;
}

.PreviewImage_Loading {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
}

.favouriteStar {
    position: absolute;
    top: 13px;
    right: 13px;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-color: #b43b4c;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ItemDetailsContainer {
    padding: 12px 12px 8px;
}

@media screen and (min-width: 1700px) {
    .ItemCard {
        width: 25%;
        flex: 0 0 25%;
    }
}
@media only screen and (max-width: 1400px) {
    .price-rating {
        margin-bottom: 6px;
    }
    .ItemNameText {
        font-size: 16px;
    }
    .ItemPriceText {
        font-size: 18px;
    }
    .StatusLocationSection {
        font-size: 14px;
        margin-bottom: 4px;
    }
    ItemCardIcon {
        height: 22px;
        width: 22px;
        flex: 0 0 22px;
    }
    .RatingSection {
        font-size: 16px;
    }
    .PreviewImage {
        min-height: 180px;
        max-height: 180px;
    }
}
@media screen and (max-width: 1199px) {
    .ItemCard {
        width: 25%;
        flex: 0 0 25%;
        padding: 0 8px 16px;
    }
}

@media screen and (max-width: 1080px) {
    .PreviewImage {
        min-height: 190px;
        max-height: 190px;
    }
    .ItemCard {
        width: 33.33%;
        flex: 0 0 33.33%;
        padding: 0 8px 16px;
    }
}

@media screen and (max-width: 991px) {
    .RatingSection,
    .ItemPriceText {
        width: 100%;
        flex: 0 0 100%;
    }
}

@media screen and (max-width: 767px) {
    .PreviewImage {
        min-height: 140px;
        max-height: 140px;
    }

    .ItemCard {
        width: 33.33%;
        flex: 0 33.33%;
    }
}

@media screen and (max-width: 575px) {
    .ItemCard {
        width: 50%;
        flex: 0 50%;
        padding: 0 10px 20px;
    }
}
