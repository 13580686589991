.unsubscribe-container {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.unsubscribe-otp-Input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px solid var(--color-accent);
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-weight: bold;
}

.card-content {
    width: 50%;
    max-width: 50%;
    max-height: 70%;
    background-color: #fff;
    border-radius: 1rem;
    padding: 3rem 1rem;
    box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
    text-align: center;
}

.resend-OTP-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
}

.resend-OTP-btn div {
    color: var(--color-accent);
    cursor: pointer;
    text-decoration: underline;
}

.card-content h1 {
    text-transform: uppercase;
    margin: 0 0 1rem 0;
}

.card-content p {
    font-size: 1.5rem;
    margin: 0 0 2rem 0;
}

.subscribe-btn {
    padding: 0.8rem 2rem;
    border-radius: 5rem;
    background: var(--color-accent);
    color: #fff;
    font-size: 0.7rem;
    border: none;
    outline: none;
    cursor: pointer;
}
