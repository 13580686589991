.ApplicationHeaderContainer {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    display: flex;
    border-bottom: 2px solid var(--color-accent);
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
}

.FlexBookingContainer {
    flex-direction: column;
}

.HeaderTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25px;
}

.ItemDetails {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ApplicationHeaderItemImage {
    height: 60px;
    width: 60px;
    border-radius: 5px;
    margin-right: 8px;
    margin-left: 8px;
    object-fit: cover;
}

.ApplicationHeaderItemTitle {
    font-size: 16px;
    font-weight: bold;
}

.ApplicationHeaderHeading {
    font-size: 25px;
    font-weight: bold;
}
.ApplicationHeaderSubheading {
    font-size: 25px;
}

@media only screen and (max-width: 1400px) {
    .ApplicationHeaderItemImage {
        height: 56px;
        width: 56px;
    }
    .ApplicationHeaderHeading {
        font-size: 20px;
        margin-right: 5px;
    }
    .ApplicationHeaderSubheading {
        font-size: 18px;
    }
    .ApplicationHeaderHeading {
        margin-right: 5px;
    }
}

@media only screen and (max-width: 495px) {
    .HeaderTextContainer {
        flex-direction: column;
    }
    .ApplicationHeaderHeading {
        text-align: center;
        font-size: 18px;
    }
    .ApplicationHeaderSubheading {
        text-align: center;
        font-size: 16px;
    }
}

.BannerBackBtn {
    position: relative;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    transform: translateX(0);
    transition: transform 0.5s;
    cursor: pointer;
}

.BannerBackBtn:hover {
    transform: translateX(-15px);
}
