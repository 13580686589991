.search_bar_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2em;
}

.search_bar_text_input {
    transition: all 250ms ease-in-out;
    border-radius: 10px;
    height: 48px;
    border: 1px solid #95272f;
    outline: none;
    padding: 8px 15px;
    width: 100%;
    font-family: 'DMSans', sans-serif;
    background-color: var(--color-white);
    min-width: 300px;
    font-size: 16px;
}

.search_bar_text_input:hover {
    min-width: 320px;
}

.search_bar_text_input:focus {
    width: 18em;
    border: 1px solid var(--color-accent);
}

.search_bar_btn {
    position: relative;
    height: 3em;
    width: 3em;
    margin: 0 0.4em;
    outline: none;
    border: none;
    cursor: pointer;
}

.search_bar_icon {
    width: 32px;
    height: 32px;
}

@media screen and (max-width: 1400px) {
    .search_bar_text_input {
        height: 40px;
    }
}
@media screen and (max-width: 1110px) {
    .search_bar_text_input {
        width: 18em;
    }
}

@media screen and (max-width: 640px) {
    .search_bar_container {
        margin: 0;
    }
}
@media screen and (max-width: 535px) {
    .search_bar_text_input {
        min-width: 200px;
        width: 8em;
    }
    .search_bar_text_input:focus {
        width: 10em;
    }
    .search_bar_container {
        margin: 0;
    }
    .search_bar_btn {
        height: 2em;
        width: 2em;
    }
}
