.UserCardContainer {
    display: flex;
    flex-direction: column;
    width: calc(35% - 2rem);
    margin-right: 2rem;
    overflow: auto;
}

.ContentContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.MessagesHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.sidebar-cards {
    height: calc(100vh - 170px);
    overflow-y: auto;
}

@media only screen and (max-width: 1400px) {
    .UserCardContainer {
        /* width: calc(35% - 4rem);
    margin-right: 4rem; */
    }
    .sidebar-cards {
        overflow-y: visible;
    }
}
@media only screen and (max-width: 495px) {
    .UserCardContainer {
        width: 100%;
        margin-right: 0;
    }

    .ContentContainer {
        justify-content: center;
    }
}
