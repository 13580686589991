.TradeCalendarContainer {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 70vh;
}

.TradeCalendarDaysContainer {
    display: grid;
    background-color: #ededed;
    position: sticky;
    top: 0;
    z-index: 60;
}
.TradeCalendarDayItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: -0.5rem; */
}
.TradeCalendarCurrentDay {
    font-size: 22px;
    font-weight: bold;
    color: var(--color-dark);
    background: var(--color-bg-light);
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.TradeCalendarDayItemContainer {
    display: flex;
    padding: 0.5rem 0;
    justify-content: center;
    border-left: 1px solid #dedede;
}
.TradeCalendarDayItemName {
    font-size: 12px;
    color: #a8a8a8;
}
.TradeCalendarDayItemDate {
    font-size: 22px;
    font-weight: bold;
    color: var(--color-dark);
}

.TradeCalendarBorrowHeader {
    font-size: 16px;
    color: var(--color-dark);
    font-weight: bold;
}

.TradeCalendarMonthLabel {
    /* { position: 'absolute', top: -10, left: 5} */
    position: absolute;
    top: -25px;
    left: 5px;
    color: var(--color-dark);
    font-size: 21px;
    font-weight: bold;
}
@media screen and (max-width: 1400px) {
    .TradeCalendarMonthLabel {
        font-size: 18px;
    }
    .TradeCalendarDaysContainer {
        padding-top: 29px !important;
    }
    .TradeCalendarBorrowHeader {
        font-size: 14px;
    }
    .TradeCalendarDayItemDate {
        font-size: 18px;
    }
    .TradeCalendarCurrentDay {
        font-size: 18px;
        height: 32px;
        width: 32px;
    }
}
