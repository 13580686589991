.DisputeModalContainer {
    border: 2px solid var(--color-accent);
    padding: 20px 40px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 550px;
    border-radius: 20px !important;
}

.MuiDialog-paper {
    border-radius: 20px !important;
}

.DisputeModalTextContainer {
    margin-bottom: 1rem;
}

.DisputeSubmit-button {
    display: flex;
    justify-content: center;
}

.heading-dispute-model {
    color: var(--color-dark);
    text-align: left;
    font: normal normal bold 20px/26px;
    font-weight: bold;
}

.DisputeCloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 23px;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b43b4c;
    color: var(--color-white);
    cursor: pointer;
}

.DisputeModalSubHeading {
    font-size: 20px;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 0.5em;
}

.DisputeModalText {
    font-size: 18px;
    margin-bottom: 0.5rem;
}

.DisputeBookingModalContent {
    padding: 0.5em 1em 0 1em;
    width: 100%;
    max-width: 550px;
}

.DesputeModal-TextArea {
    width: 100%;
    height: 3em;
    padding: 1em 0.8em;
    border-radius: 10px;
    outline: none;
    border: 1px solid var(--color-accent);
    background-color: var(--color-white);
}

.DesputeModal-TextArea:focus {
    border: 3px solid var(--color-accent);
}

.DesputeModal-TextArea {
    height: 10em;
    padding: 0.5em;
}

.DisputeBookingModalTitle {
    font-size: 26px;
    color: var(--color-dark);
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.checkbox-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 10px 0;
    font-size: 15px;
}

.DisputeBookingModalHeader {
    margin-bottom: 15px;
}

.main-container-images {
    border-radius: 2px;
    border: 1px solid var(--color-accent);
    padding: 0.5em;
    margin-bottom: 15px;
}

.Dispute__ItemPictures__Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    width: 100%;
}

.Dispute__ItemPictures__Preview {
    width: 48%;
    margin-bottom: 1em;
    border: 1px solid var(--color-accent);
    border-radius: 5px;
    padding: 0.5em;
    position: relative;
}

.PostItem__ItemPictures__Preview {
    width: 45%;
    margin-bottom: 1em;
    border: 1px solid var(--color-accent);
    border-radius: 5px;
    padding: 0.5em;
    position: relative;
}

.Dispute__ItemPictures__Add__Container {
    padding: 5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DisputeBookingModalOptions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    margin-top: 1em;
}

@media only screen and (max-width: 1400px) {
    .DisputeBookingModalTitle {
        font-size: 20px;
    }

    .DisputeModalSubHeading {
        font-size: 16px;
    }

    .DisputeModalText {
        font-size: 14px;
        margin-bottom: 0.2rem;
    }

    .checkbox-container {
        margin: 4px 0;
        font-size: 14px;
    }

    .DesputeModal-TextArea {
        height: 6em;
        padding: 0.5em;
    }

    .main-container-images {
        border-radius: 8px;
    }
}

@media only screen and (max-width: 767px) {
    .DisputeModalContainer {
        width: 100%;
        padding: 15px !important;
    }
}