.UserShedNav {
    width: 280px;
    flex: 0 0 280px;
    padding: 0 15px 0 0;
    font-size: 20px;
    color: var(--color-dark);
    height: calc(100vh - 112px);
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.UserShedNav a {
    display: block;
}

.UserShedNav ul {
    height: calc(100% - 60px);
    overflow-y: auto;
    padding: 0 15px 0 0;
    margin: 0;
}

.UserShedNav ul li {
    list-style: none;
}

.counter-pin {
    margin-left: auto;
    background: #aa314e;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 40px;
    min-width: 25px;
}

.menu-icon {
    height: 24px;
    width: 24px;
}

.UserShedNav__LinkFlex {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 1em;
    user-select: none;
    border: 2px solid;
    border-radius: 10px;
    border-color: rgba(176, 59, 75, 0);
    transition: 0.3s;
    gap: 15px;
    font-size: 16px;
}

.UserShedNav__LinkFlex:hover {
    cursor: pointer;
}

.UserShedNav__LinkFlex--Active {
    border-color: rgba(176, 59, 75, 1);
}

.menu-icon svg {
    min-width: 24px;
    width: 24px;
    max-height: 24px;
    visibility: visible !important;
}

.UserShedNav__NewMessage {
    position: absolute;
    top: 6px;
    left: 35px;
    width: 12px;
    height: 12px;
    background: rgba(176, 59, 75, 1);
    border-radius: 50%;
}

.LogoutButton {
    width: 100%;
    background-color: var(--color-accent);
    font-size: 16px;
    font-family: 'DMSans';
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: var(--color-white);
    border-radius: 8px;
    padding: 10px 16px;
    gap: 15px;
}

.logout-section {
    padding-right: 22px;
}

.UserShedNav__SecondaryLink {
    color: var(--color-dark);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.UserShedNav__SecondaryLink:hover {
    cursor: pointer;
}

.UserShedNav__SecondaryLink--active {
    font-weight: bold;
}

.UserShedNav__SecondaryLink__ExtraSpacing {
    margin-top: 3em;
}

.HL {
    border: 1px solid #ebebeb;
    margin: 1.5em 0;
}
@media only screen and (max-width: 1400px) {
    .UserShedNav {
        width: 230px;
        flex: 0 0 230px;
    }
    .UserShedNav__LinkFlex {
        padding: 7px 10px;
        font-size: 14px;
    }
    .menu-icon svg {
        min-width: 22px;
        width: 22px;
        max-height: 22px;
    }
    .LogoutButton {
        font-size: 14px;
        padding: 8px 16px;
        font-weight: 500;
    }
    .UserShedNav__SecondaryLink__ExtraSpacing {
        margin-top: 2em;
    }
    .UserShedNav__SecondaryLink {
        font-size: 14px;
    }
    .HL {
        margin: 0.8em 0;
    }
}
@media screen and (max-width: 1080px) {
    .UserShedNav {
        width: 240px;
        flex: 0 0 240px;
    }
}

@media screen and (max-width: 991px) {
    .UserShedNav ul {
        height: auto;
    }
    .UserShedNav__SecondaryLink__ExtraSpacing {
        margin: 0 !important;
    }
    .UserShedNav {
        width: 100%;
        flex: 0 0 100%;
        height: auto;
        padding: 0;
    }
    .UserShedNav ul {
        padding: 0;
    }
}
