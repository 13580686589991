.SearchFilterBar {
    border-bottom: 2px solid var(--color-accent);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SearchFilterBarFlex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 6em;
    font-size: 16px;
    user-select: none;
}

.SearchFilterText:hover {
    cursor: pointer;
}

.FilterActive {
    font-size: 16px;
    font-weight: bold;
}

.FilterContainer {
    position: relative;
    min-width: 15%;
    text-align: center;
    display: flex;
    align-items: center;
}

.FilterPopout {
    position: absolute;
    top: 36px;
    border: 2px solid var(--color-accent);
    border-top: none;
    border-radius: 0 0 15px 15px;
    background-color: var(--color-white);
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.FilterPopoutCat {
    min-width: 500px;
    left: -20px;
}

.FilterPopoutLoc {
    min-width: 450px;
    left: -60px;
}

.FilterPopoutPrice {
    min-width: 400px;
    left: -15%;
}

.FilterPopoutRating {
    min-width: 350px;
    left: -70px;
}

.FilterPopoutDel {
    min-width: 350px;
    left: -70px;
}

.hlPopout {
    margin-top: 0;
    margin-bottom: 1em;
    width: 80%;
}

.MainBodyPopout {
    padding: 0 15px 15px;
    width: 100%;
    font-size: 14px;
}

.CategoryFiltersFlex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.CategoryFilterDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 5px 8px;
    background-color: var(--color-white);
    border: 1px solid #c7c7c7;
    margin: 0 5px 5px 0;
    height: 38px;
}

.CategoryFilterDivActive {
    border: 1px solid #9e1e29;
}

.CategoryFilterDiv:hover {
    cursor: pointer;
}

.CategoryFilterIcon {
    max-height: 30px;
    margin-right: 0.5em;
}

.FilterButtonSave {
    font-family: 'DMSans', sans-serif;
    font-size: 16px;
    color: var(--color-white);
    padding: 8px 25px;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: var(--color-accent);
}

.FilterButtonSave:disabled {
    background-color: #d08787 !important;
}

.PostcodeFlex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.PostcodeText {
    font-size: 18px;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 0.5em;
}

.SearchRadiusText {
    font-size: 16px;
}

.PostcodeInput {
    font-size: 20px;
    font-family: 'DMSans', sans-serif;
    font-weight: bold;
    text-align: center;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    width: 50%;
}

.PostcodeInput:focus {
    outline: none;
}

.LocationSliderFlex {
    display: flex;
    align-self: center;
}

.SearchRadiusValue {
    margin-left: 1em;
    font-size: 17px;
}

.MapContainerSmall {
    width: 100%;
    height: 250px;
}

.MinMaxFlex {
    display: flex;
    justify-content: space-between;
}

.MinMaxHeader {
    width: 100%;
    /* margin-left: 1em; */
    margin-bottom: 0.5em;
    font-size: 16px;
}

.Checkbox-Header {
    width: 100%;
    margin-bottom: 0.5em;
    font-size: 16px;
}
.PriceInput {
    font-size: 16px;
    font-family: 'DMSans', sans-serif;
    font-weight: bold;
    text-align: center;
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    width: 70%;
    padding: 8px 12px;
    text-align: left;
}

.PriceInput:focus {
    outline: none;
}

.SaveButtonFlex {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.DeliveryFlex {
    display: flex;
    justify-content: space-around;
}

.DeliveryButton {
    padding: 7px 15px;
    color: var(--color-bg-light);
    background-color: var(--color-accent);
    border: 1px solid var(--color-accent);
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    width: 100px;
}

.DeliveryButtonInactive {
    background-color: var(--color-white);
    font-weight: normal;
    color: #000;
}

.StarsFlex {
    display: flex;
    justify-content: center;
}

.RatingFilterHeader {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-bottom: 0;
    align-items: center;
}

.StarClick:hover {
    cursor: pointer;
}

.filter-menu {
    display: flex;
}

.active-filter {
    width: 10px;
    height: 10px;
    background-color: var(--color-accent);
    border-radius: 5px;
    margin-left: 5px;
    display: block;
}

.search-in-mobile,
.mobile-flter {
    display: none;
}

@media only screen and (max-width: 1400px) {
    .SearchFilterBarFlex {
        padding: 10px 11em;
    }

    .FilterPopoutCat {
        min-width: 400px;
        left: -131px;
    }

    .ReactIconCategory {
        width: 18px;
        height: 18px;
        max-width: 18px;
    }

    .MainBodyPopout {
        padding: 0 10px 10px;
    }

    .CategoryFilterDiv {
        padding: 5px;
        height: 30px;
        font-size: 12px;
        margin: 0 8px 8px 0;
    }

    .DeliveryButton {
        padding: 5px 12px;
        font-size: 14px;
    }

    .FilterPopoutDel {
        min-width: 237px;
        left: -59%;
    }

    .FilterPopoutRating {
        min-width: 215px;
        left: -59%;
    }

    .RatingFilterHeader {
        font-size: 14px;
    }

    .StarIcon {
        height: 16px;
        width: 16px;
    }

    .FilterPopoutPrice {
        min-width: 301px;
        left: -68%;
    }

    .MinMaxHeader {
        margin-left: 0em;
        font-size: 14px;
    }

    .PriceInput {
        font-size: 12px;
        padding: 6px 12px;
    }

    .FilterButtonSave {
        padding: 5px 25px;
        font-size: 14px;
    }

    .FilterPopoutLoc {
        min-width: 400px;
        left: -108px;
    }

    .WithStyles\(ForwardRef\(InputBase\)\)-input-14 {
        padding: 0.7em 1.2em !important;
    }

    .makeStyles-form-132 {
        margin-bottom: 5px !important;
    }

    .WithStyles\(ForwardRef\(Slider\)\)-thumb-16 {
        width: 18px !important;
        height: 18px !important;
        margin-top: -8px !important;
    }

    .SearchRadiusValue {
        margin: 0em 0em 0.3em 1em;
        font-size: 16px;
    }

    .PostcodeText {
        font-size: 16px;
        margin-bottom: 4px;
    }

    .LocationSliderFlex {
        align-items: center;
    }
}

.selectLabel {
    margin-left: 10px;
}

@media only screen and (max-width: 1127px) {
    .FilterPopout.FilterPopoutDel {
        min-width: 300px;
        left: -135px;
    }

    .FilterPopout.FilterPopoutRating {
        min-width: 300px;
        left: -135px;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
    .search-in-mobile {
        display: block;
    }

    .search-in-mobile {
        max-width: 25px;
    }

    .search-in-mobile img.HeaderLogo {
        margin: 0;
        max-width: 25px;
    }

    .mobile-flter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 10px;
    }

    .SearchFilterBar {
        transition: all 250ms ease-in-out;
        position: fixed;
        max-width: 400px;
        width: 90%;
        right: -420px;
        top: 0;
        background: #fff;
        padding: 20px;
        z-index: 9999;
        height: 100vh;
        display: block;
        border: none;
    }

    .mobile-flter button {
        box-shadow: none;
        color: #a71e42;
    }

    .SearchFilterBar.active {
        right: 0;
    }

    .mobile-flter h3 {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        margin: 0;
    }

    .FilterContainer {
        padding: 1%;
        display: block;
        text-align: left;
        font-size: 16px;
        text-align: left;
        padding-bottom: 20px;
    }

    .FilterPopout {
        position: static;
        border-top: 2px solid var(--color-accent);
        border-radius: 10px;
    }

    .SearchFilterBarFlex {
        padding: 12px 0;
        display: flex;
        flex-direction: column;
    }

    .FilterPopout.FilterPopoutRating,
    .FilterPopout.FilterPopoutPrice,
    .FilterPopout.FilterPopoutDel,
    .FilterPopout.FilterPopoutLoc,
    .FilterPopout.FilterPopoutCat {
        width: 100%;
        top: auto;
        min-width: auto;
        margin: 0 auto;
        left: 0;
        right: 0;
        max-width: 100%;
    }
}