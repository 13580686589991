.PostItem__ItemPictures__Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2em;
    width: 100%;
}

.PostItem__ItemPictures__Add__Container {
    padding: 5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PostItem__ItemPictures__Preview {
    width: 45%;
    margin-bottom: 1em;
    border: 1px solid var(--color-accent);
    border-radius: 5px;
    padding: 0.5em;
    position: relative;
}

.PostItem__TextArea {
    resize: none;
    font-weight: 400;
    height: 150px;
    width: 100%;
    min-height: 150px;
}

.PostItem__Complete__TextCenter {
    text-align: center;
}

.PostItem__Complete__Bold {
    font-weight: bold;
}

.PostItem__Complete__ItemPreview {
    border: 1px solid #707070;
    border-radius: 5px;
    padding-bottom: 100%;
    width: 100%;
    position: relative;
}

.PostItem__Complete__ItemImage {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
}

.post-item-next{
   padding: 1rem;
   width: 100%;
}

.PostItem__Complete__ItemDetails__Container {
    width: 100%;
}

.item-specification-Flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.add-key-value-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ButtonAddKeyValue {
    padding: 0.6em;
    background-color: var(--color-white);
    font-size: 15px;
    color: var(--color-accent);
    font-weight: bold;
    border-radius: 10px;
    border: 1px solid var(--color-accent);
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2.2em;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
@media only screen and (max-width: 1400px) {
    .PostItem__TextArea {
        font-size: 14px;
        min-height: 150px;
        height: 150px;
        width: 100%;
    }
    .PostItem__ItemPictures__Add__Container {
        padding: 1em 0;
    }
    .PostItem__ItemPictures__Container {
        margin-top: 1em;
    }
}
