.SentMessageTextContainer {
    background-color: var(--color-bg-light);
    position: relative;
    border-radius: 5px;
    padding: 12px 18px 12px 12px;
    margin: 0;
    word-wrap: break-word;
    min-width: 160px;
    /* max-width: calc(100% - 90px); */
}

.SentMessageContainer {
    display: flex;
    width: 70%;
    position: relative;
    padding: 0;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0 0 20px auto;
    gap: 25px;
}

.SentMessageTriangle {
    position: absolute;
    right: -13px;
    bottom: 0px;
}

.SentMessageImageContainer {
    width: 40px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1400px) {
    .SentMessageTextContainer {
        font-size: 14px;
        padding: 10px 13px 10px 12px;
    }
}
@media only screen and (max-width: 495px) {
    .SentMessageTextContainer {
        width: 85%;
    }

    .SentMessageImageContainer {
        width: 15%;
    }

    .SentMessageTriangle {
        right: calc(15% - 8px);
    }
}
