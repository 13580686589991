.bg_lend_your_stuff {
    background-image: url(../../../assets/Images/Marketings/Background/LEND\ YOUR\ STUFF_oneshot_10073665.jpg);
}

.bg_lbs {
    background-image: url(../../../assets/TexturedBackgrounds/footer-red-bg.jpg);
}

.bg_lys_signup {
    background-image: url(../../../assets/Images/Marketings/Images/LEND\ YOUR\ STUFF-painter\ bottom\ of\ page.jpg);
}

.button_font {
    font-size: 1em;
}

.description_list,
.how_it_works_list {
    padding-left: 0.5em;
    margin: 0;
}

.description_list li,
.how_it_works_list li {
    list-style: none;
    font-size: 1em;
    margin: 0.3em;
}

.description_list li::before {
    content: '\f00c';
    font-family: fontAwesome;
    color: var(--color-dark);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.how_it_works_list li::before {
    content: '\2022';
    color: var(--color-dark);
    font-weight: bold;
    display: inline-block;
    margin-left: -1em;
}

.lbs_quote_text,
.lbs_quote_lgtext {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-dark);
    font-family: 'Kameron', serif;
    font-weight: bold;
    font-size: 2em;
    line-height: 1.3;
}

.lbs_quote_lgtext-borrow {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-white);
    font-family: 'Kameron', serif;
    font-weight: bold;
    font-size: 2em;
    width: 20em;
}

.lbs_quote_text {
    height: 100%;
    width: 100%;
    padding: 0 20%;
}

.lbs_quote_lgtext {
    height: auto;
    margin: 0 auto;
}

.image_half_container {
    width: 50%;
    /* height: 100%; */
    padding: 3em 0;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.dark_mode_text {
    color: var(--color-white);
}

.center_quote_btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .description_list {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 768px) {
    .disable_guiter {
        display: none !important;
    }
    .lbs_quote_text {
        font-size: 1.5em;
        padding: 0 15px;
    }
    .image_half_container {
        padding: 2em 0;
    }
}

@media screen and (max-width: 420px) {
    .lbs_quote_lgtext {
        width: 100%;
    }
}
