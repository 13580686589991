.LenderSwitchLabel {
    position: absolute;
    font-size: 12px;
    color: var(--color-white);
    top: 2px;
    left: -5px;
    user-select: none;
    pointer-events: none;
}

.SwitchContainer {
    position: relative;
}
