.NoContentButton {
    margin: 0 5px;
    cursor: pointer;
    background-color: var(--color-accent);
    color: white;
    border-radius: 10px;
    padding: 1.4rem 1rem;
    font-size: 1.2rem;
    width: max-content;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.NoContentLogo {
    margin: 1rem;
    max-width: 5rem;
}

.NoContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
}
.NoContentText {
    font-size: 16px;
}
.NoContentTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    text-align: center;
}
.NoContentHeaderText {
    font-weight: bold;
    font-size: 25px;
    color: var(--color-dark);
    margin-bottom: 0.6rem;
}
.NoContentImage {
    max-width: 26rem;
    width: 80%;
    margin-bottom: 2rem;
}
@media only screen and (max-width: 1400px) {
    .NoContentText {
        font-size: 14px;
    }
    .NoContentHeaderText {
        font-size: 22px;
        margin-bottom: 0.5rem;
    }
    .NoContentImage {
        max-width: 18rem;
    }
    .NoContentButton {
        padding: 1rem;
        font-size: 16px;
    }
}
