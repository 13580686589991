.bg_contact_us {
    background-image: url(../../../assets/Images/Marketings/Background/CONTACT\ US_TS139674_m.jpg);
}

.bg_contact_us::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
        0,
        0,
        0,
        0.5
    ); /* Adjust the opacity (0.5) to make it darker/lighter */
}

.contact_img_bg {
    background-image: url(../../../assets/Images/Marketings/Graphics/lys_mowing.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 5em 0;
}

.contact_input_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    margin-bottom: 20px;
}

.contact_input_box {
    width: 100%;
    padding: 0 1em;
    margin-bottom: 1em;
}

.input_fields_left,
.input_fields_right {
    width: 50%;
}

.contact_details_box {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-left: 9px;
    margin-top: 10px;
}

.contact_number_box,
.contact_email_box {
    margin: 0 1em;
}

.contact_detail_title {
    font-size: 16px;
    color: var(--color-dark);
}
.contact_detail_title.form_data {
    font-size: 16px;
    max-width: 70%;
    padding: 4rem 0px 0px;
}

.contact_detail_content {
    margin: 0;
    font-size: 16px;
}

.submit_btn_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.submit_message_btn {
    width: 11em;
    height: 40px;
    margin: 0.8em 0;
    font-weight: bold;
    color: var(--color-white);
    background-color: var(--color-accent);
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in;
}

.submit_message_btn:hover {
    box-shadow: 15px 15px 25px 0px rgba(0, 0, 0, 0.1);
}

.news_letter_box {
    display: flex;
    justify-content: space-between;
}

.news_title_box {
    max-width: 85%;
}

.news_main_title,
.news_sub_title {
    margin: 0;
    padding: 0 0.6em;
}

.news_main_title {
    color: var(--color-dark);
    font-weight: bold;
}

.news_sub_title {
    font-size: 0.8em;
}

.news_checkbox_container {
    position: relative;
    max-width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.news_checkbox_input {
    margin-right: 5px;
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.news_checkbox_input:checked {
    background-color: red;
    border-color: red;
}
.red-label {
    color: var(--color-accent);
    margin-bottom: 10px;
}

.news_checkbox_input:checked + .news_checkbox_label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: red;
}

.news_checkbox_label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid black;
}

@media screen and (max-width: 1440px) {
    .contact_details_box {
        align-items: center;
    }
    .contact_number_box {
        text-align: center;
    }
    .contact_img_bg {
        margin: 2em;
    }
    .contact_input_box {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 1124px) {
    .responsive_contact_container {
        height: 100%;
    }
    .contact_box_responsive {
        flex-direction: column;
    }
    .input_fields_left,
    .input_fields_right {
        width: 100%;
    }
    .contact_detail_title {
        font-size: 14px;
    }
    .contact_detail_content {
        font-size: 14px;
    }
    .contact_input_container {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 800px) {
    .contact_img_bg {
        display: none;
    }
    .contact_input_container {
        width: 100%;
    }
    .contact_detail_title.form_data {
        max-width: 100%;
        padding-top: 25px;
    }
    .contact_details_box {
        justify-content: space-around;
    }
    .contact_input_box {
        padding: 0;
    }
}
