.HeaderBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url(../../assets/TexturedBackgrounds/header-bg.jpg);
    background-position: -200px -200px;
    padding: 17px 0;
    transition: all 200ms ease-in-out;
    top: -400px;
}

.HeaderBarSticky {
    position: fixed;
    top: 0;
    z-index: 999;
}

.HeaderLogo {
    margin: 0 2em;
    max-width: 3.5em;
    transition: all 200ms ease-in-out;
}

.HeaderBarSticky .HeaderLogo {
    max-width: 3.5em;
}

.SearchWrapper {
    /* display: flex; */
    align-items: center;
    width: 100%;
    max-width: 320px;
}

.LoginWrapper {
    display: flex;
    align-items: center;
    margin-right: 2em;
}

.HeaderSearchDiv {
    position: relative;
    width: 100%;
    margin: 0;
    margin-top: 10px;
}

.HeaderSearchDiv .SearchButton {
    position: absolute;
    top: 4px;
    z-index: 9;
    right: 4px;
    background: #a60f3c;
    border-radius: 8px;
    height: 40px;
    width: 40px;
}

.HeaderBarSticky .HeaderSearchDiv .SearchButton {
    height: 40px;
}

.cat-dropdown {
    position: absolute;
    background: #fff;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 15%);
    z-index: 9;
    width: 100%;
    border: 2px solid #95272f;
}

.cat-dropdown ul li {
    margin: 0;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 16px;
    color: #5a5a5a;
    font-weight: 500;
    border-bottom: 2px solid #b4b4b4;
}

.cat-dropdown ul li:nth-last-child(1) {
    border-bottom: none;
    padding-bottom: 0;
}

.HeaderSearchInput {
    width: 100%;
    font-size: 16px;
}

.SearchForm {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.recentSearch-text {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-top: -2px;
}

@media only screen and (max-width: 1400px) {
    .recentSearch-text {
        font-size: 12px;
    }
    .HeaderLogo {
        max-width: 3em;
    }
    .HeaderBar {
        padding: 10px 0;
        /* padding-bottom: 8px; */
    }
    .HeaderBarSticky .HeaderLogo {
        max-width: 3em;
    }
    .SearchWrapper {
        max-width: 310px;
    }
    .HeaderSearchDiv .SearchButton {
        height: 40px;
        width: 40px;
    }
    .cat-dropdown {
        padding: 5px 15px 10px;
    }
}

@media only screen and (max-width: 1200px) {
    .SearchWrapper {
        max-width: 420px;
    }
}

@media only screen and (max-width: 1080px) {
    .SearchWrapper {
        max-width: 350px;
    }
}
@media only screen and (max-width: 800px) {
    .LoginWrapper {
        margin-right: 1.5rem;
    }
}
@media only screen and (max-width: 767px) {
    .HeaderBarSticky {
        position: static;
    }
}

@media only screen and (max-width: 575px) {
    .SearchWrapper {
        display: none;
    }

    .HeaderLogo {
        max-width: 3.5em;
        margin: 0 1rem;
    }

    .LoginWrapper {
        margin-right: 1.5rem;
    }
}
