.MapMarker {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #b03b4b;
    border: 3px solid #f6efe6;
    transform: translate(-15px, -15px);
    box-shadow:
        0px 2px 3px -1px rgb(0 0 0 / 20%),
        0px 2px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.MapMarker:hover {
    cursor: pointer;
}
