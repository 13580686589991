.addItemBox {
    height: 220px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    margin-bottom: 40px;
    color: var(--color-accent);
    display: flex;
    font-size: 6rem;
    align-items: center;
    justify-content: center;
}

.addItemButton {
    width: 100%;
    background-color: var(--color-accent);
    font-size: 18px;
    font-family: 'DMSans';
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: var(--color-white);
    border-radius: 10px;
    padding: 0.5em 0;
}
@media only screen and (max-width: 1400px) {
    .addItemBox {
        height: 264px;
        margin-bottom: 21px;
    }
}
