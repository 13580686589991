.bg_rent_stuff {
    background-image: url(../../../assets/Images/Marketings/Background/RENT\ STUFF_TS581578_m.jpg);
}

.search_title_section {
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    text-align: center;
    margin: 0 auto;
}

.search_title {
    color: var(--color-white);
    font-weight: bold;
    font-family: 'Kameron', serif;
    font-size: 3em;
    margin: 0;
}

.get_start_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}

.get_start_title {
    color: var(--color-dark);
    font-family: 'Kameron', serif;
    font-weight: bold;
    font-size: 1.5em;
    margin: 0;
}

.get_start_title_light {
    color: var(--color-dark);
    font-family: 'Kameron', serif;
    font-size: 1.5em;
    line-height: 110%;
}

.add_padding_right {
    padding-right: 4em;
}

@media screen and (max-width: 1024px) {
    .responsive_box_for_search {
        height: 100%;
        padding: 10px 20px;
    }
}

@media screen and (max-width: 768px) {
    .add_padding_right {
        padding-right: 0;
    }
}

@media screen and (max-width: 700px) {
    .search_title {
        font-size: 2em;
    }
}

@media screen and (max-width: 400px) {
    .search_title {
        font-size: 1.5em;
    }
}