.PickerMain {
    border-radius: 20px;
    background-color: var(--color-bg-light);
    width: 330px;
    margin-bottom: 1em;
    user-select: none;
}

.PickerHeader {
    display: flex;
    justify-content: center;
    font-size: 17px;
    color: var(--color-dark);
    font-weight: bold;
    padding: 1em 0;
}

.MorningAfternoonFlex {
    display: flex;
    justify-content: space-between;
    padding: 0 1em 1em 1em;
}

.TimeSlotDiv {
    width: 48%;
    background-color: var(--color-white);
    border-radius: 20px;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-dark);
    user-select: none;
}

.Pointer {
    cursor: pointer;
}

.TimeSlotDiv .DayText {
    font-size: 17px;
    font-weight: bold;
}

.TimeSlotDiv .TimeText {
    font-size: 14px;
}

.TimeSlotDiv .DaySelectedText {
    font-size: 17px;
    font-weight: bold;
    color: 'white';
}

.BackgroundMorning {
    background-color: var(--color-dark);
    color: var(--color-white);
}

.BackgroundAfternoon {
    background-color: var(--color-accent);
    color: var(--color-white);
}

.MorningUnavailable {
    background-color: rgb(211, 211, 211);
    color: gray;
}
.AfternoonUnavailable {
    background-color: rgb(211, 211, 211);
    color: gray;
    cursor: default;
}

@media only screen and (max-width: 1400px) {
    .PickerMain {
        width: 252px;
    }
    .PickerHeader {
        font-size: 16px;
        font-weight: bold;
        padding: 0.5em 0;
    }
    .TimeSlotDiv .DaySelectedText,
    .TimeSlotDiv .DayText {
        font-size: 14px;
    }
    .TimeSlotDiv .TimeText {
        font-size: 12px;
    }
}
@media only screen and (max-width: 576px) {
    .DayDotsFlex {
        font-size: 12px;
    }
    .PickerMain {
        width: 220px;
    }
    .PickerHeader {
        font-size: 14px;
    }
    .TimeSlotDiv .DaySelectedText,
    .TimeSlotDiv .DayText {
        font-size: 12px;
    }
    .TimeSlotDiv .TimeText {
        font-size: 10px;
    }
}
