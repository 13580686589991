.LoginButton {
    background-color: var(--color-dark);
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 1em;
    color: var(--color-white);
    width: 95%;
    font-size: 18px;
    font-family: 'DMSans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    max-height: 50px;
}

.LoginIcon {
    height: 24px;
    display: inline;
    margin-right: 1em;
}
@media only screen and (max-width: 1400px) {
    .LoginButton {
        padding: 11px 16px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 495px) {
    .LoginButton {
        padding: 1em 10%;
    }
}
