.SearchSection {
    background-image: url(../../assets/Images/search_section_bg.jpg);
    background-position: 0 450px;
    background-size: cover;
    height: 450px;
    width: 100%;
}

.SearchSectionWrapper {
    width: 50%;
    margin: 4em auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-content: center;
}

.SearchSectionTitle {
    font-size: 44px;
    font-weight: bold;
    color: white;
    width: 80%;
    margin: auto;
    line-height: 1.08;
    margin-bottom: 10px;
}

.SearchSectionFilters {
    background-color: rgba(51, 56, 79, 0.3);
    width: 100%;
    border-radius: 15px;
    padding: 2em;
    margin-bottom: 20px;
}

.SearchFiltersRowFlex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.DividingLine {
    border: none;
    border-bottom: 1px solid var(--color-white);
    opacity: 0.1;
    margin: 16px 0;
}

.PriceFilterSearch {
    width: 45%;
    /* background-color: var(--color-white); */
    border-radius: 10px;
    /* border: 1px solid #95272f; */
    display: flex;
    min-width: 9rem;
}

.PriceFilterSelect {
    font-family: 'DMSans', sans-serif;
    border: none;
    font-size: 20px;
    height: 100%;
    width: 100%;
    padding: 0 5px;
    outline: none;
}

.vl {
    border-right: 1px solid rgba(51, 56, 79, 0.3);
    margin: 5px 0;
}

.PriceFilterContainer {
    position: relative;
    width: 50%;
    /* margin: 0 10px 0 0; */
}

.FloatingLabel {
    position: absolute;
    left: 10px;
    font-weight: bold;
    color: #bcbcbc;
    font-size: 10px;
}

.SearchButtonLarge {
    background-color: var(--color-accent);
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 8px 20px;
    font-family: 'DMSans', sans-serif;
    color: var(--color-white);
    width: max-content;
    font-size: 20px;
    min-width: 120px;
}

.SearchButtonLargeDisabled {
    background-color: #bcbcbc;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 0.6em 2em;
    font-family: 'DMSans', sans-serif;
    color: var(--color-dark);
    width: max-content;
    font-size: 24px;
}

.SearchInputContainer {
    width: 35%;
}

.input-25 {
    width: 25%;
}

.CategoryInputField {
    color: var(--color-dark);
    font-size: 20px;
    font-weight: bold;
}

.SearchButtonLargeFlex {
    display: flex;
    align-items: center;
    gap: 4px;
}

.SearchIcon35 {
    height: 22px;
    margin-right: 3px;
}

.MainContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin: 2em 0;
}

.RecommendedSection {
    width: 100%;
}

.MainContentHeader {
    font-weight: bold;
    color: #626262;
    font-size: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;
}

.CategoriesSection {
    width: 100%;
}

.ItemCardSection,
.SearchItemCardSection {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}

.ItemCardSectionyourshed {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}

.CategoryCardSection {
    /* padding: 1em 4em; */
    /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}

.CategoryImageDiv {
    min-height: 331px;
    flex: 0 1 22%;
    padding: 1em;
    border-radius: 10px;
    color: var(--color-white);
    background-repeat: no-repeat;
}

.CategoryImageDiv:hover {
    cursor: pointer;
}

.CategoryTitle {
    font-size: 24px;
    /* text-align: center; */
    font-weight: bold;
}

.CategoryItemTotal {
    font-size: 20px;
}

.Automotive {
    background-image: url(../../assets/Images/Categories/Automotive.png);
    background-size: 100%;
}

.Gardening {
    background-image: url(../../assets/Images/Categories/Gardening.png);
    background-size: 100%;
}

.HandTools {
    background-image: url(../../assets/Images/Categories/HandTools.png);
    background-size: 100%;
}

.PowerTools {
    background-image: url(../../assets/Images/Categories/PowerTools.png);
    background-size: 100%;
}

.CategoryIcon {
    margin: 10px 0 0 5px;
    max-height: 30px;
}

@media only screen and (max-width: 1400px) {
    .SearchSection {
        background-position: 0 450px;
        height: 450px;
    }

    .SearchSectionWrapper {
        margin: 2em auto;
        padding-bottom: 50px;
    }

    .SearchSectionTitle {
        font-size: 40px;
        margin-bottom: 10px;
        width: 100%;
    }
}

@media only screen and (max-width: 1199px) {
    .ItemCardSectionyourshed {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
    }
}

@media only screen and (max-width: 1180px) {
    .SearchSectionWrapper {
        width: 75%;
    }
}

@media only screen and (max-width: 900px) {
    .CategoryImageDiv {
        min-height: 270px;
    }
}

@media only screen and (max-width: 767px) {
    .CategoryImageDiv {
        min-height: 220px;
    }

    .SearchSectionTitle {
        font-size: 28px;
    }
}

@media only screen and (max-width: 600px) {

    .SearchInputContainer,
    .PriceFilterSearch,
    .input-25 {
        width: 100%;
    }
    .SearchFiltersRowFlex {
        gap: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .CategoryImageDiv {
        min-height: 320px;
    }

    .SearchButtonLarge {
        font-size: 16px;
    }

    .ItemCardSection,
    .SearchItemCardSection {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 -10px;
    }

    .SearchSectionWrapper {
        width: 100%;
    }

    .SearchSectionTitle {
        width: 100%;
    }

    .MainContentWrapper {
        width: 100%;
    }

    .CategoryCardSection {
        padding: 0.5rem 0.5rem;
    }

    .CategoryImageDiv {
        margin-right: 5px;
    }

    .SearchSectionFilters {
        padding: 1em;
    }

    .SearchFiltersRowFlex {
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 430px) {
    .CategoryImageDiv {
        min-height: 229px;
    }
}