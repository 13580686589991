.TradeCalendarItem {
    border-radius: 15px;
    cursor: pointer;
    margin: 0.2rem 0;
    height: max-content;
    z-index: 50;
    font-size: calc(0.4vw + 0.5rem);
}

.TradeCalendarItemPicture {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 0.5rem;
    object-fit: cover;
}

.TradeCalendarItemLend {
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: var(--color-dark);
    padding: 0.5rem;
    color: white;
    border-radius: 10px;
}
.TradeCalendarItemBorrow {
    display: flex;
    align-items: center;
    background-color: var(--color-bg-light);
    padding: 0.5rem;
    color: var(--color-dark);
    border-radius: 10px;
}
.TradeCalendarItemPending {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: var(--color-dark);
    background-color: white;
    border: 1px solid var(--color-dark);
    border-radius: 10px;
}

.TradeCalendarItemLendCancelled {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    background-color: var(--color-dark);
    color: white;
    border: 1px solid var(--color-dark);
    border-radius: 10px;
    flex-wrap: wrap;
}

.TradeCalendarItemBorrowCancelled {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: var(--color-bg-light);
    color: var(--color-dark);
    border-radius: 10px;
}

.TradeCalendarVerticalText {
    display: block;
    font-weight: bold;
    font-size: 10;
}
