.TermsConditions__Container {
    border-radius: 10px;
    border: 1px solid #c7c7c7;
    padding: 2em;
    width: 70%;
    height: 100%;
    margin: 0 auto;
}

.TermsConditionsScroll {
    width: 100%;
    height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid var(--color-accent);
    border-radius: 10px;
    padding: 1em;
}

@media only screen and (max-width: 1400px) {
    .TermsConditions__Container {
        margin: 11px auto;
        padding: 1.5em;
        height: auto;
    }
}

@media only screen and (max-width: 991px) {
    .TermsConditions__Container {
        width: 95%;
    }
}