.Disputes__FlexMain {
    display: flex;
    justify-content: space-between;
    margin: 0 -12px;
}

.Disputes__Main {
    width: 30%;
    flex: 0 0 30%;
    padding: 0 12px;
}

.Disputes__Detail {
    width: 70%;
    flex: 0 0 70%;
    padding: 0 12px;
}

.item-dispute-status {
    width: calc(100% - 40px);
    flex: calc(100% - 40px);
    padding-left: 18px;
}

.Disputes__Container {
    border-radius: 10px;
    height: 100%;
    border: 1px solid #c7c7c7;
    padding: 15px;
    width: 100%;
    min-height: calc(100vh - 190px);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 190px);
}

.Disputes__Title {
    font-weight: bold;
    font-size: 25px;
    color: var(--color-dark);
    margin-bottom: 0.5em;
}

.Disputes__UserFlex {
    padding: 1em 0;
    display: flex;
    width: 100%;
}

.item-dispute-status h3 {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 18px;
}

.item-dispute-status p {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Dispute-list-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 12px;
    background: #f5f5f5;
    border: 2px solid transparent;
    padding: 12px;
    border-radius: 8px;
}

.Dispute-list-item.active {
    border-color: #ac172c;
}

.item-card-parent .ItemCard {
    width: 100%;
    padding: 0 12px;
    height: 100%;
}

.item-card-parent .product-list {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 8px;
    box-shadow: none;
}

.item-card-parent .product-list a {
    width: 180px;
    height: 180px;
}

.item-card-parent {
    width: 50%;
}

.item-cost-card {
    width: 50%;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    overflow: hidden;
    padding: 15px;
}

.Dispute__Item_Avatar {
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px !important;
}

.lender-borrowe-container {
    border-bottom: 1px solid #e1e1e1;
}

.despute-status {
    padding: 4px 12px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    background: var(--color-bg-light);
}

.dispute-info-text {
    padding: 10px;
    background: #f1f1f1;
    border-radius: 10px;
    margin: 15px 0;
}
.dispute-info-text span {
    font-weight: 600;
}
.dispute-info-card {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 15px;
}

.dispute-user-role {
    padding: 5px 15px;
    border-radius: 7px;
    color: #ac172c;
    background-color: #ac172c00;
    border: 2px solid #ac172c;
    font-weight: 800;
}

.dispute-overview-container {
    height: calc(100% - 15%);
    align-items: center;
    display: flex;
    justify-content: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.RESOLVED {
    background: #0aa500;
    color: #fff;
}

.PARTIALLY_RESOLVED {
    background: #0f53c8;
    color: #fff;
}

.IN_PROGRESS {
    background: #c7b405;
    color: #fff;
}

.REJECTED {
    background: #e5421e;
    color: #fff;
}

.disputes-header {
    height: 15%;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.disputes-header h4 {
    font-size: 22px;
    font-weight: 600;
    color: #ac172c;
}

.lander-box h3 {
    font-weight: 600;
    font-size: 18px;
}

.land-items-box h3 {
    margin-bottom: 10px;
}

.lander-box p {
    font-weight: 400;
    font-size: 15px;
}

.lander-box {
    margin-bottom: 15px;
}

.deputes-detail-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #f1f1f1;
    border-radius: 10px;
    margin: 10px 0;
}

.user-list {
    display: flex;
    align-items: center;
    gap: 15px;
}

.lander-box hr {
    border-color: #e1e1e1;
    margin: 10px 0;
}

.user-name {
    display: flex;
    align-items: center;
    gap: 15px;
}

.land-items-box {
    align-items: center;
    gap: 16px;
    padding-top: 15px;
    border-bottom: 1px solid #e1e1e1;
}

.item-card-parent .PreviewImage {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.item-cost-card .TradeSidebarSection {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: none !important;
}

span.ApplicationFooterDetailsHeader {
    font-size: 20px;
    margin-bottom: 5px;
}

.item-cost-card .TradeSidebarCostFlex {
    border-color: #e1e1e1 !important;
}

.booking-date .ApplicationFooterDetailsContainer {
    margin-bottom: 10px;
}

.booking-date .ApplicationFooterArrowContainer {
    width: 40px;
}

.booking-date .ApplicationFooterDetails {
    background: #f5f5f5;
    border-radius: 10px;
    padding: 15px;
    width: 40%;
    min-width: 40%;
    font-size: 14px;
}

.booking-date {
    padding-top: 15px;
    border-top: 1px solid #e1e1e1;
}

.item-card-parent .ItemDetailsContainer {
    width: calc(100% - 180px);
    flex: 0 0 calc(100% - 180px);
    padding-left: 22px;
}

.lending-events {
    display: flex;
    gap: 40px;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.lending-events-status {
    /* capitalize; */
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 16px !important;
    /* capitalize font-bold text-[16px] */
}

.booking-details-card {
    display: flex;
    margin: 0 -12px 15px;
}

.disputes-lending {
    margin-top: 8px;
}

.disputes-lending .lending-events {
    gap: 10px;
}

.disputes-lending .lending-box {
    padding: 10px 15px;
    border-radius: 8px;
    background: #f5f5f5;
}

.desputes-status {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}

.status {
    background: #f1f1f1;
    padding: 8px 20px;
    border-radius: 8px;
    flex: 0 0 calc(33.33% - 10px);
}

.desputes-status h3 {
    font-size: 14px;
    font-weight: 400;
}

.desputes-status p {
    font-size: 18px;
    font-weight: 600;
}

.disputes-details-box h3 {
    margin: 0 0 2px;
    font-size: 16px;
}

.cost {
    background: #f1f1f1;
    padding: 8px 15px;
    border-radius: 10px;
    flex: 0 0 calc(33.33% - 10px);
}

.disputes-details-box {
    display: flex;
    margin: 0 -15px 15px;
}

.d-details-list {
    width: 50%;
    flex: 0 0 50%;
    padding: 0 15px;
}

.d-details-list ol {
    list-style: revert-layer;
    padding-left: 15px;
}

.d-detail-gallery {
    width: 50%;
    flex: 0 0 50%;
    padding: 0 15px;
}

.d-detail-gallery p {
    margin-bottom: 10px;
}

.d-detail-images {
    display: flex;
    gap: 12px;
}

.d-detail-thumb {
    height: 80px;
    width: 20%;
    border-radius: 8px;
    border: 1px solid #ccc;
    overflow: hidden;
}

.d-detail-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media only screen and (max-width: 1400px) {
    span.ApplicationFooterDetailsHeader {
        font-size: 18px;
        margin-bottom: 3px;
    }
    .item-dispute-status h3 {
        font-size: 16px;
    }
    .disputes-header h4 {
        font-size: 18px;
    }
    .dispute-info-text {
        margin: 12px 0;
        font-size: 14px;
    }
    .lander-box h3,
    .desputes-status p {
        font-size: 16px;
    }

    .lander-box p {
        font-size: 14px;
    }
    .d-details-list ol {
        font-size: 14px;
    }
    .status,
    .cost {
        min-height: 80px;
    }
    .UserShed__MainContent {
        width: calc(100% - 224px);
    }
    .lending-events,
    .lander-box {
        margin-bottom: 0px;
    }
}
@media only screen and (max-width: 495px) {
    .AccountSettings__FlexMain {
        flex-direction: column;
        align-items: center;
    }
    span.ApplicationFooterDetailsHeader {
        font-size: 14px;
    }
}
