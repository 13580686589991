.ApplicationOverviewContainer {
    margin-bottom: 30px;
    width: 70%;
    border: solid 1px #b4b4b4;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}
.ItemOverviewPrice {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-dark);
}
.ApplicationOverviewHeader {
    color: var(--color-dark);
    font-size: 24px;
    font-weight: bold;
}

.ItemOverviewItemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ItemOverviewEditButton {
    color: gray;
    cursor: pointer;
}
.ItemOverviewEditButton:hover {
    text-decoration: underline;
}
.ApplicationOverviewSubHeader {
    font-size: 20px;
    color: var(--color-dark);
    font-weight: bold;
}
.ItemOverviewSmallText {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: bold;
}
.ItemOverviewSendButton {
    background-color: var(--color-accent);
    margin-top: 20px;
    align-self: center;
    width: 80%;
    border-radius: 8px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    cursor: pointer;
}

.ItemOverviewSendText {
    font-size: 15px;
}
.ItemOverviewBorrowContainer {
    padding-bottom: 15px;
    border-bottom: 2px solid #31364c;
    margin-bottom: 15px;
}
.ItemOverviewCardContainer {
    border-bottom: 1px solid#ebebeb;
    margin-bottom: 0.8rem;
}

@media only screen and (max-width: 1400px) {
    .ItemOverviewPrice {
        font-size: 14px;
    }
    .ApplicationOverviewHeader {
        font-size: 20px;
    }
}
@media only screen and (max-width: 495px) {
    .ApplicationOverviewContainer {
        width: 90%;
        padding: 20px;
    }
    .ApplicationOverviewSubHeader {
        font-size: 16px;
    }
    .ApplicationOverviewSubHeader + div {
        font-size: 14px;
    }
    .ApplicationOverviewHeader {
        font-size: 20px;
    }
    .ItemOverviewEditButton {
        font-size: 14px;
    }
}
