.DeleteModalContainer {
    /* border: 2px solid var(--color-accent); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em 1em !important;
}

.UpgradeToLenderModalContainer {
    padding: 50px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.test .MuiPaper-rounded {
    border-radius: 25px;
}

.lenderCapabilitiesModalContainer {
    border: 2px solid var(--color-accent);
    padding: 45px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.DeleteModalText {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
}

.DeleteModalHeading {
    font-size: 26px;
    text-align: center;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 18px;
}

.DeleteModalTextContainer {
    margin-bottom: 1rem;
}

.lenderCapabilitiesModalHeading {
    font-size: 24px;
    text-align: center;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 0.5em;
}

.lenderCapabilitiesModalSubHeading {
    font-size: 20px;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 0.5em;
}

.lenderCapabilitiesCloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 23px;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b43b4c;
    color: var(--color-white);
    cursor: pointer;
}

.lenderCapabilitiesModalTextContainer {
    margin-bottom: 1rem;
}

.CapabilitiesModalText {
    font-size: 18px;
    margin-bottom: 0.5rem;
}
.EditItemMainWrapper {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 4em;
}

.EditItemInfoWrapper {
    width: 45%;
    font-family: 'DMSans', sans-serif;
}

.EditItemPicturesWrapper {
    width: 45%;
}

@media only screen and (max-width: 1400px) {
    .EditItemMainWrapper {
        margin-top: 2em;
        justify-content: space-around;
    }
    .DeleteModalHeading {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .DeleteModalText {
        font-size: 14px;
        margin-bottom: 12px;
    }
}
@media only screen and (max-width: 940px) {
    .EditItemMainWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 4em;
    }

    .UpgradeToLenderModalContainer {
        padding: 25px !important;
    }

    .DeleteModalHeading {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .EditItemInfoWrapper {
        min-width: 100%;
        font-family: 'DMSans', sans-serif;
    }

    .EditItemPicturesWrapper {
        min-width: 100%;
    }
}

@media only screen and (max-width: 1127px) {
    .FilterPopoutDel {
        min-width: 200px;
        left: -90px;
    }
}
