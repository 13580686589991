.AccountSettings__FlexMain {
    display: flex;
    justify-content: space-between;
}

.AccountSettings__Main {
    width: 50%;
    padding: 5px 15px;
}

.AccountSettings__Container {
    border-radius: 16px;
    border: 1px solid #e9e9e9;
    padding: 20px;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0 10px 25px rgb(0 0 0 / 15%);
}

.AccountSettings__Title {
    font-weight: bold;
    font-size: 20px;
    color: var(--color-dark);
    margin-bottom: 8px;
}

.AccountSettings__UserFlex {
    padding: 0 0 10px 0;
    display: flex;
    width: 100%;
}

.AccountSettings__Avatar {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.AccountSettings__UserFlex .AccountSettings__Avatar {
    width: 80px;
    height: 80px;
}

.AccountSettings__UserDetails {
    padding-left: 1em;
    width: calc(100% - 80px);
}

.AccountSettings__UserName {
    font-size: 18px;
    text-transform: capitalize;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 5px;
}

.ac-box-setting {
    padding: 15px;
    margin: 0 0 15px;
    border: 1px solid #bb190e;
    border-radius: 8px;
    background: #ffe4e2;
}

.ac-box-setting p {
    margin: 0 0 10px;
}

.ac-status {
    padding: 5px 14px;
    border-radius: 20px;
    display: block;
    overflow: hidden;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
}

.unverify {
    background: #b60031;
    color: #fff;
}

.panding {
    background: #c1b421;
    color: #fff;
}

.verified {
    background: #5ea700;
    color: #fff;
}

.AccountSettings__Ratings {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    color: #000;
    flex-wrap: wrap;
    width: 100%;
}

.AccountSettings__Ratings span {
    padding-right: 0.5em;
}

.AccountSettings-Medium {
    font-weight: 500;
}

.AccountSettingsRatingContainer {
    display: flex;
    align-items: center;
}

.AccountSettings__AccountProductsFlex {
    display: flex;
    justify-content: space-between;
}

.AccountSettings__UpdateHeader {
    font-weight: bold;
    font-size: 15px;
    color: var(--color-dark);
    margin-bottom: 0.5em;
}

.AccountSettings__SaveButton {
    width: 60%;
}

.AccountSettings__ButtonFlex {
    display: flex;
    justify-content: center;
}

.AccountSettings__SyncButtonFlex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
    font-weight: bold;
    color: var(--color-dark);
}

.AccountSettings__SavedCard h2 {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 900;
}

.chip {
    position: relative;
}

.edit-card-btn svg {
    width: 16px;
}

.edit-card-btn {
    background: #fff;
    padding: 7px;
    border-radius: 20px;
    font-size: 12px !important;
    height: 35px;
    width: 35px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chip img {
    max-width: 50px;
    margin: 60px 0 0 0;
}

.AccountSettings__SavedCard span {
    font-size: 18px;
    margin-bottom: 10px;
}

.AccountSettings__SavedCard h4 {
    font-size: 20px;
    margin-bottom: 25px;
}

.AccountSettings__SavedCard {
    display: flex;
    color: var(--color-dark);
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem 0;
    position: relative;
    justify-content: space-between;
    max-width: 440px;
    height: 240px;
    color: #fff;
    width: 100%;
}

.chip .delete-ic {
    background: #fff;
    height: 40px;
    display: flex;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 10px;
    margin: -10px -10px 0 auto;
}

.delete-ic {
    width: 25px;
    height: 25px;
}

.delete-ic svg {
    width: 23px;
    height: 23px;
}

.AccountSettings__SyncButton__Text {
    width: 50%;
}

.AccountSettings__BodyText {
    font-size: 14px;
    margin: 1em 0;
}

@media only screen and (max-width: 1400px) {
    .AccountSettings__Container {
        padding: 15px;
    }

    .AccountSettings__Title {
        font-size: 18px;
    }

    .AccountSettings__UserName {
        font-size: 16px;
    }

    .AccountSettings__Ratings {
        font-size: 14px;
    }

    .AccountSettings__AccountProductsFlex {
        font-size: 14px;
    }

    .AccountSettings__Main {
        width: 50%;
        padding: 5px 15px;
    }

    .AccountSettings__FlexMain {
        justify-content: center;
        margin: 0 -15px;
    }

    .AccountSettings__SavedCard h2 {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .AccountSettings__SavedCard h4 {
        font-size: 18px;
        margin-bottom: 18px;
    }

    .AccountSettings__SavedCard span {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .AccountSettings__SavedCard {
        height: 212px;
        margin: 1rem 0 0;
    }

    .AccountSettings__Container {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 1080px) {
    .AccountSettings__UserFlex .AccountSettings__Avatar {
        width: 40px;
        height: 40px;
    }

    .AccountSettings__UserDetails {
        padding-left: 1em;
        width: calc(100% - 40px);
    }
}

@media only screen and (max-width: 991px) {
    .AccountSettings__FlexMain {
        flex-wrap: wrap;
    }

    .AccountSettings__Main {
        width: 100%;
        padding: 5px 15px;
    }
}

@media only screen and (max-width: 576px) {
    .AccountSettings__Title {
        font-size: 16px;
    }

    .AccountSettings__UserName {
        font-size: 14px;
    }

    .AccountSettings__Container div>span {
        font-size: 14px;
    }
}

@media only screen and (max-width: 495px) {
    .AccountSettings__FlexMain {
        flex-direction: column;
        align-items: center;
    }
}