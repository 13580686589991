.TradeSidebarContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    border-left: 1px solid #d6d6d6;
    width: 340px;
    flex: 0 0 340px;
}

.fixed-content {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
}
.scroll-container {
    display: flex;
    overflow-x: auto;
    width: 300px; /* Set the container width */
    gap: 10px; /* Add space between cards */
    scrollbar-width: thin; /* For Firefox */
  }
.TradeSidebarSubHeading {
    font-size: 20px;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 20px;
}
.cash-on-delivery-label{
    border: 1px solid var(--color-accent);
    color: var(--color-dark);
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.TradeSidebarStepExtendHeading {
    font-size: 18px;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 10px;
}
.TradeSidebarCostFlex {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.4rem;
}

.TradeSidebarSection {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid#ebebeb;
}

.TradeSidebarHeading {
    font-size: 22px;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 10px;
}

.BorrowerAddressLocation {
    font-size: 1.2em;
}

.TradeSidebarUserRatingContainer {
    display: flex;
    align-items: center;
}

.TradeSidebarUserName {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-dark);
}
.TradeSidebarUserContainer {
    display: flex;
    align-items: center;
}
.TradeSidebarUserAvatar {
    margin-right: 1rem;
}
.TradeSidebarLocationContainer {
    flex-direction: column;
    display: flex;
}

.ItemOverviewSmallText {
    font-size: 13px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1400px) {
    .TradeSidebarSection {
        margin-bottom: 8px;
        padding-bottom: 8px;
    }
    .TradeSidebarSubHeading {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .TradeSidebarHeading {
        font-size: 16px;
    }
    .TradeSidebarCostFlex {
        font-size: 14px;
    }
    .TradeSidebarLocationContainer {
        font-size: 14px;
    }
    .TradeSidebarUserName {
        font-size: 16px;
    }
    .TradeSidebarUserRatingContainer {
        font-size: 14px;
    }
    .TradeSidebarUserContainer {
        margin-bottom: 7px;
    }
}
@media only screen and (max-width: 1300px) {
    /* .TradeSidebarContainer {
    padding: 1rem 0.5rem;
  } */
}
@media only screen and (max-width: 495px) {
    .TradeSidebarContainer {
        width: 300px;
    }
}
