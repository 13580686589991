.SearchSortFlex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 15px;
    align-items: center;
}

.clear-filter-button {
    height: 35px;
    /* background-color: var(--color-accent); */
    border-radius: 10px;
    border: 2px solid var(--color-accent);
    color: var(--color-accent);
    font-size: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.clear-filter-button svg {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.SortArrow {
    height: 15px;
    padding: 3px;
    margin-left: 0.5em;
}

.SortSection:hover {
    cursor: pointer;
}

.PagesText {
    color: #626262;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.no-result-found {
    height: 350px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.PaginationSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1em;
}

.PaginationFlex {
    display: flex;
    align-items: center;
    user-select: none;
    max-width: 100%;
}

.paginate-number {
    display: flex;
    max-width: calc(100% - 100px);
    flex: calc(100% - 100px);
    overflow-x: auto;
}

.PaginationArrow {
    width: 35px;
    height: 35px;
    background-color: var(--color-accent);
    border-radius: 6px;
    color: var(--color-white);
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5em;
}

.PaginationPage {
    height: 35px;
    width: 35px;
    flex: 0 0 35px;
    background-color: rgba(226, 226, 226, 0.59);
    font-size: 16px;
    margin: 0 0.2em;
    font-weight: bold;
    color: #a5a5a5;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PaginationArrow:hover,
.PaginationPage:hover {
    cursor: pointer;
}

.PaginationPageActive {
    background-color: #f2e8d3;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
}

.SuggestedItemsSection {
    margin: 1em 0;
}

.SearchMainText {
    font-size: 16px;
    color: #626262;
    font-weight: bold;
    position: relative;
}

.MuiSelect-select.MuiSelect-select {
    font-size: 16px;
    padding: 14px 35px 14px 15px !important;
    height: 48px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1400px) {
    .container.SearchMainContainer {
        padding: 0 4rem;
    }

    .SearchSortFlex {
        margin: 13px 0 12px;
    }

    .MuiSelect-select.MuiSelect-select {
        font-size: 14px;
        font-weight: 400;
    }
}

@media only screen and (max-width: 992px) {
    .container.SearchMainContainer {
        padding: 0 10px;
    }
}
