.CalendarContainer {
    width: 100%;
    margin-bottom: 30px;
}

.CalendarRow {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.CalendarPadding {
    grid-column-start: 1;
    grid-column-end: 8;
    display: flex;
    justify-content: center;
}

.CalendarStart {
    grid-column-start: 4;
}

.CalendarRowErrorContainer {
    position: relative;
    width: 100%;
}

.CalendarMonth {
    color: var(--color-dark);
    font-size: 16px;
    font-weight: bold;
}

.CalendarYear {
    color: var(--color-dark);
    font-size: 16px;
    font-weight: bold;
}

.CalendarInfoContainer {
    margin-bottom: 20px;
    text-align: center;
}

.CalendarCurrentYearLabel {
    font-size: 40px;
    font-weight: bold;
    color: var(--color-dark);
    position: relative;
    right: 7.5rem;
    top: 1.5rem;
}

@media only screen and (max-width: 495px) {
    .CalendarRow {
        grid-template-columns: repeat(7, 48px);
    }
}
