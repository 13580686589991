.marketing_container {
    position: relative;
    width: 100%;
    height: 100%;
}

.marketing_image_xlg_container {
    padding: 50px 0;
}

.marketing_image_fit_container,
.marketing_image_xlg_container,
.marketing_image_lg_container,
.marketing_img_md_container,
.marketing_img_sm_container,
.marketing_img_flexible_container {
    width: 100%;
    position: relative;
    background-size: cover;
}

.marketing_img_sm_container {
    padding-right: 10%;
    padding-left: 10%;
}

.marketing_img_md_find {
    height: 65vh;
    width: 100%;
    position: relative;
    background-size: cover;
}

.marketing_image_fit_container {
    height: 100%;
}

.marketing_image_lg_container {
    height: 85vh;
}

.marketing_img_md_container {
    height: 85vh;
}

.marketing_img_sm_container {
    height: 70vh;
}

.marketing_img_sm_banner {
    padding-top: 4vh;
    padding-bottom: 4vh;
    background-color: var(--color-light-grey);
}

.marketing_img_flexible_container {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
}

.marketing_img_flexible_banner {
    height: fit-content;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.marketing_screenshot {
    padding-top: 4em;
}

.main_title_section .main_title {
    margin-bottom: 16px;
}

.main_title_section {
    position: absolute;
    height: 100%;
    width: 37%;
    top: 15%;
    left: 143px;
}

.main_title,
.download_main_title {
    font-family: 'Kameron', serif;
    font-weight: bold;
    color: var(--color-white);
    line-height: 1;
    margin: 0;
}

.main_title {
    font-size: 3.5em;
}

.dark_title {
    color: #2d3144;
}

.title_shadow {
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
}

.sub_title_shadow {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
}

.download_main_title {
    font-size: 1.8em;
}

.main_sub_title,
.download_sub_title {
    color: var(--color-white);
    font-size: 1.1em;
    margin: 0;
}

.dark_sub_title {
    color: #2d3144;
    margin-bottom: 10px;
}

.download_app_section {
    width: 70%;
    margin-top: 1.5em;
}

.app_icons {
    margin-left: 0.5em;
    width: 1.1em;
    height: 1.1em;
}

.half_screen_center {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.six_four_screen_center {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.eight_two_screen_center {
    width: 80%;
    height: 100%;
}

.graphic_image {
    padding: 2.5em;
    height: 100%;
}

.screenshot_image {
    padding: 2em;
}

.picture_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.marketing_main_title {
    font-family: 'Kameron', serif;
    color: var(--color-dark);
    font-size: 2.5em;
    font-weight: bold;
    margin: 0;
    padding: 0 0 15px;
    line-height: 120%;
}

.marketing_main_description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.9em !important;
}

.center_text_image {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 0;
}

.insert_image_background {
    position: relative;
    background-size: cover;
}

/* utils */

.flex_box {
    display: flex;
}

.responsive_img_size {
    max-width: 100%;
}

.height100p {
    height: 100% !important;
}

.row_section {
    flex-direction: row;
}

.column_section {
    flex-direction: column;
}

.no_left_padding {
    padding-left: 0 !important;
}

.no_right_padding {
    padding-right: 0 !important;
}

.no_bottom_padding {
    padding-bottom: 0 !important;
}

.padding_sm_left {
    padding-left: 2em;
}

.margin_bottom {
    margin: 0.8em 0;
}

.center_items {
    display: flex;
    justify-content: center;
}

.sm_width {
    width: 21em;
}

.align_start {
    align-items: flex-start;
}

.adjusting_box_size {
    max-width: 30em;
}

.bg_top {
    background-image: url(../../assets/Images/Marketings/Background//HOME_oneshot_10053215.jpg);
    max-width: 100%;
}

.coming-soon {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comming-soon-lbs-icon {
    height: 200px;
    width: 200px;
}

.coming-soon-text {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 50px;
    color: var(--color-accent);
    font-family: 'Kameron', serif;
    font-weight: 600;
}

.img-commingsoon {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.article_section {
    flex-direction: column;
}

.eligible {
    padding-left: 0px;
}

.article_section:last-child {
    align-items: flex-start;
}

.top_info_left_desc,
.top_info_right_desc {
    width: 46%;
}

.top_info_top_section {
    width: 100%;
    height: 20em;
    justify-content: center;
}

.top_info_textured_section {
    width: 100%;
    max-width: 35em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.top_info_smaller_section {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 0;
}

.top_section_smaller_box {
    width: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.top_info_bottom_section {
    margin-top: 2em;
}

.top_grid_section {
    width: 100%;
    max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 0 3em;
    padding-bottom: 1em;
    gap: 1em;
}

.search_items_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5em 0;
}

.category_title_section {
    width: 100%;
    text-align: center;
    padding: 0 30px 30px;
}

.category_main_title {
    font-family: 'Kameron', serif;
    color: var(--color-dark);
    font-weight: bold;
    font-size: 2.5em;
    padding-top: 0.2em;
    margin: 0;
}

.category_sub_heading {
    color: var(--color-dark);
    font-family: 'Kameron', serif;
    font-weight: bold;
    font-size: 1.5em;
    margin: 0;
}

.category_sub_title {
    max-width: 35em;
    padding: 0 0.5em;
    margin: 0 auto;
}

.category_card_section {
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    flex-direction: row;
    justify-content: center;
    padding: 0 2.5em;
    padding-bottom: 3em;
    margin: 0 auto;
}

@media screen and (max-width: 1124px) {
    .top_grid_section {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        gap: 1em;
        padding: 0 35px;
    }
}

@media screen and (max-width: 850px) {
    .top_info_smaller_section {
        padding: 0;
    }

    .top_info_top_section {
        height: 20em;
    }
}

@media screen and (max-width: 768px) {
    .article_section {
        padding-left: 60px;
    }
}

@media screen and (max-width: 700px) {
    .article_section {
        padding: 0 2em;
        padding-bottom: 2em;
    }
}

@media screen and (max-width: 600px) {
    .disable_img {
        display: none !important;
    }

    .top_section_smaller_box {
        display: none !important;
    }

    .download_app_section {
        width: 100%;
    }

    .top_info_smaller_section {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 1em 20px;
    }

    .top_info_top_section {
        height: fit-content;
    }
}

@media screen and (max-width: 530px) {
    .top_grid_section {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        gap: 1em;
        padding: 0 20px;
    }

    .category_card_section {
        padding: 0 15px;
    }

    .category_card_container {
        width: 48%;
    }
}

/* breakpoint */
@media screen and (max-width: 1440px) {
    .main_title_section {
        width: 45%;
        left: 130px;
    }

    .graphic_image {
        padding: 2em;
    }
}

@media screen and (max-width: 1024px) {
    .marketing_img_md_container {
        height: 60vh;
    }

    .marketing_img_sm_container {
        height: 50vh;
    }

    .main_title {
        font-size: 2.8em;
    }

    .main_sub_title,
    .download_sub_title {
        font-size: 0.9em;
    }

    .download_main_title {
        font-size: 1.5em;
    }

    .marketing_main_title {
        font-size: 1.8em;
        margin-top: 0;
        padding-top: 1em;
        padding-right: 0;
    }

    .marketing_main_description {
        font-size: 0.6em;
    }

    .responsive_img_size {
        width: 100%;
        height: auto;
    }

    .adjusting_box_size {
        padding: 0 1em;
    }
}

@media screen and (max-width: 768px) {
    .marketing_img_md_container {
        height: 40vh;
    }

    .main_title_section {
        width: 80%;
        height: auto;
        bottom: 0;
        left: 60px;
    }

    .main_title {
        font-size: 2em;
    }

    .main_sub_title,
    .download_sub_title {
        font-size: 0.6em;
    }

    .download_main_title {
        font-size: 1.3em;
    }

    .straight_column {
        height: 100%;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
    }

    .full_width {
        width: 100%;
        height: auto;
        padding: 15px;
    }

    .responsive_img_size {
        width: 100%;
        height: auto;
    }

    .marketing_img_flexible_container {
        padding: 0 15px;
    }

    .responsive_img_sm_size {
        width: 20em;
        height: 20em;
    }

    .graphic_image {
        padding: 0;
    }
}

@media screen and (max-width: 700px) {}

@media screen and (max-width: 600px) {

    /* .marketing_img_md_container {
        height: 35vh;
    } */
    .main_title_section {
        width: 55%;
        height: auto;
        top: 5%;
        left: 15%;
    }

    .straight_column {
        height: 100%;
    }
}

@media screen and (max-width: 500px) {
    .main_title_section {
        width: 100%;
        top: 0;
        left: 0;
        padding: 15px;
    }

    .main_title {
        font-size: 1.8em;
    }

    .download_main_title {
        font-size: 1em;
    }

    .graphic_image {
        padding: 0;
    }
}

/* background */

.bg_wall {
    background-image: url(../../assets/Images/Marketings/Background/TopBgWall.png);
}

.bg_grey {
    background-color: #f5f5f5;
}

.bg_white {
    background-color: var(--color-white);
}

.bg_dark {
    background-color: var(--color-dark);
}