.ApplicationFooter {
    position: sticky;
    background-color: var(--color-white);
    width: 100%;
    margin-bottom: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    border-top: 3px solid var(--color-accent);
    border-bottom: 3px solid var(--color-accent);
    padding-top: 10px;
    padding-bottom: 10px;
}

.ApplicationFooterContainer {
    width: 45%;
    max-width: 400px;
}
.ApplicationFooterDetailsContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
}
.ApplicationFooterButtonContainer {
    display: flex;
    justify-content: space-between;
}
.ApplicationFooterClearButton {
    cursor: pointer;
    border: 1px solid var(--color-accent);
    border-radius: 10px;
    min-width: 45%;
    display: flex;
    justify-content: center;
    max-height: 40px;
    align-items: center;
}
.ApplicationFooterLargeNextButton {
    width: 100%;
}
.ApplicationFooterClearRemoved {
    display: none;
}
.ApplicationFooterNextButton {
    cursor: pointer;
    background-color: var(--color-accent);
    color: white;
    border-radius: 10px;
    min-width: 45%;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ApplicationFooterPriceContainer {
    display: flex;
    justify-content: space-between;
    font-size: large;
    border-bottom: 1px solid #dedede;
    padding-bottom: 8px;
}
.ApplicationFooterDetails {
    min-width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.ApplicationFooterTime {
    color: var(--color-accent);
    font-size: 16px;
    font-weight: bold;
}

.ApplciationFooterArrow {
    color: var(--color-accent);
    font-size: 35px;
}
.ApplicationFooterArrowContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ApplicatonFooterPrice {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-dark);
}
.ApplicationFooterDetailsHeader {
    color: var(--color-dark);
    font-size: 17px;
    font-weight: bold;
}
.ApplicationFooterDay {
    font-weight: bold;
    font-size: 16px;
}

@media only screen and (max-width: 1400px) {
    .ApplicationFooterDetails,
    .ApplicationFooterDay,
    .ApplicationFooterTime {
        font-size: 14px;
    }
}
@media only screen and (max-width: 495px) {
    .ApplicationFooterContainer {
        width: 90%;
    }
    .ApplicatonFooterPrice {
        font-size: 18px;
    }
    .ApplicationFooterPriceContainer {
        font-size: 16px;
        padding-bottom: 0px;
    }
}
