.ApplicationItemCardContainer {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
}

.ApplicationItemCardHeader {
    font-weight: bold;
    font-size: 16px;
}

.ApplicationItemRatingContainer {
    display: flex;
    align-items: center;
}

.ApplicationItemCheckContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ApplicationItemPrice {
    font-size: 18px;
    color: var(--color-accent);
}

.ApplicationItemExtra {
    font-size: 14px;
    color: #a8a8a8;
}

.ApplicationItemRating {
    font-size: 17px;
}

.ApplicationItemDetailsContainer {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
}

.ApplicationItemImage {
    height: 70px;
    width: 70px;
    margin-right: 15px;
    object-fit: cover;
}

@media only screen and (max-width: 1400px) {
    .ApplicationItemCardContainer {
        margin-bottom: 6px;
        margin-top: 6px;
    }
    .ApplicationItemPrice {
        font-size: 14px;
    }
    .ApplicationItemCardHeader {
        font-size: 14px;
    }
}
