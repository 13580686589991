.SelectInput {
    font-size: 16px;
    height: 48px !important;
    border-radius: 8px;
    outline: none;
    width: 100%;
    font-family: 'DMSans', sans-serif;
    background-color: var(--color-white);
}

.SelectInput--Thin {
    padding: 0.7em;
}

.hl {
    margin: 5px 5px 8px;
    opacity: 0.1;
}

.SelectInput__Option {
    min-height: 25px;
    max-height: 40px;
}
