.ReceivedMessageTextContainer {
    background-color: #ebebeb;
    max-width: calc(100% - 60px);
    border-radius: 5px;
    position: relative;
    padding: 12px 12px 12px 18px;
    word-wrap: break-word;
    min-width: 160px;
}

.ReceivedMessageContainer {
    position: relative;
    display: flex;
    width: 70%;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0 auto 20px 0;
    gap: 20px;
}

.ReceivedMessageTriangle {
    position: absolute;
    bottom: 0;
    left: -16px;
}

.ReceivedMessageImage {
    height: 40px;
    width: 40px;
}

.ReceivedMessageImageContainer {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 495px) {
    .ReceivedMessageTextContainer {
        width: 85%;
    }

    .ReceivedMessageImageContainer {
        width: 15%;
    }

    .ReceivedMessageTriangle {
        left: calc(15% - 8px);
    }
}
