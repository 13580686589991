.footer_container {
    width: 100%;
    height: 100%;
}

.footer_header_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: url(../../../assets/TexturedBackgrounds/footer-red-bg.jpg);
    padding: 10px 0;
}

.footer_header_left_section {
    display: flex;
    align-items: center;
    padding: 0 2em;
}

.footer_header_logo {
    width: 5em;
    height: 5em;
    border-radius: 20px;
}

.footer_header_title_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.5em;
}

.footer_header_main_title {
    color: var(--color-white);
    font-family: 'Kameron, serif';
    font-weight: bold;
    font-size: 1.6em;
    margin: 0;
}

.footer_header_sub_title {
    color: var(--color-white);
    font-size: 1em;
    margin: 0;
}

.footer_header_right_section {
    display: flex;
    flex-direction: row;
    padding: 2em 0;
}

.app_button {
    margin-right: 0.5em;
    height: 50px;
    width: 180px;
}

.footer_content_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/Images/Marketings/Background/TopBgWall.png);
}

.footer_middle_logo {
    height: 5em;
    width: 5em;
    margin-bottom: 0.5em;
}

.footer_first_title,
.footer_first_desc,
.footer_last_title,
.footer_last_desc {
    margin: 0;
    max-width: 200px;
}

.bigger_icon {
    font-size: 28px;
}

.footer_first_title,
.footer_last_title {
    color: var(--color-dark);
    font-weight: bold;
    font-family: 'Kameron, serif';
}

.footer_first_desc,
.footer_last_desc {
    font-size: 0.8em;
}

.footer_content_box {
    margin: 2em 0;
    display: flex;
    flex-direction: column;
}

.footer_section_title_main {
    margin: 0.1em 0;
    cursor: pointer;
}

.footer_section_title {
    margin: 0.1em 0;
    cursor: pointer;
    max-width: 200px;
}

.footer_section_title_main {
    color: var(--color-dark);
    font-weight: bold;
}

.footer_bottom_container {
    display: flex;
    justify-content: space-between;
    background: var(--color-dark);
    color: var(--color-white);
}

.footer_bottom_left {
    margin-left: 2em;
}

.footer_bottom_right {
    margin-right: 2em;
}

.social_media_icons {
    color: var(--color-dark);
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.5em;
    cursor: pointer;
}

.margin_top {
    margin-top: 1em;
}

.subscribe_email_container {
    position: relative;
    width: 100%;
}

.subscribe_email_input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1px solid #95272f;
    padding: 0 0.3em;
    outline: none;
}

.email_arrow_icon {
    width: 1em;
}

button {
    background: transparent;
}

.subscribe_button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0.2em;
    width: 24px;
    height: 50px;
    border-radius: 30px;
    padding-right: 0;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none !important;
}

.footer_section_title:not(:last-child) {
    margin-bottom: 0.5em;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    background-color: var(--color-white);
    padding: 40px;
    border-radius: 4px;
}

.modal-content h2 {
    font-size: 2em;
    margin-top: 0;
    font-family: 'Kameron', serif;
    font-weight: bold;
}

.modal-content p {
    margin-bottom: 20px;
    font-size: 0.9em;
}

.modal-content button {
    background-color: #ddd;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.policy-container {
    padding: 4em 6em;
    width: 100%;
    position: relative;
}

.policy-list {
    padding-left: 3em;
    font-size: 0.9em;
    list-style-type: disc;
}

.protection-policy-list {
    padding-left: 3em;
    font-size: 0.9em;
    list-style-type: disc;
}
.policy-header {
    top: 15%;
    left: 10.5%;
}

.policy-title {
    font-size: 2.2em;
    font-family: 'Kameron', serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.policy-sub-title {
    font-size: 1em;
    margin: 0;
}

.policy-sub-title a {
    text-decoration: underline;
    color: #b4132e;
}

@media screen and (max-width: 1400px) {
    .footer_section_title:not(:last-child) {
        margin-bottom: 0em;
    }

    .subscribe_button {
        top: 0;
    }

    .footer_content_box {
        font-size: 14px;
    }
}

@media screen and (max-width: 1100px) {
    .footer_header_container {
        flex-direction: column;
        padding: 15px 0;
    }

    .footer_header_left_section,
    .footer_header_right_section {
        padding: 0.5em;
        justify-content: center;
    }

    .footer_content_container {
        gap: 30px 15px;
    }

    .footer_content_box {
        max-width: 160px;
    }
}

@media screen and (max-width: 800px) {
    .footer_content_container {
        justify-content: space-between;
        padding: 50px 20px;
        gap: 30px 20px;
        flex-wrap: wrap;
    }

    .footer_content_box {
        max-width: 250px;
    }

    .footer_content_box .flex_box {
        justify-content: center;
    }

    .footer_middle_logo {
        margin: 0 auto 25px;
    }

    .footer_first_title,
    .footer_first_desc,
    .footer_last_title,
    .footer_last_desc {
        max-width: 100%;
    }


    .footer_content_box {
        min-width: 5em;
    }

    .footer_content_box:nth-last-child(1),
    .footer_content_box:nth-child(1) {
        width: 100%;
        text-align: center;
        max-width: 100%;
    }

    .footer_section_title_main {
        font-size: 20px;
    }

    .footer_content_box {
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .footer_header_logo {
        width: 4em;
        height: 4em;
    }

    .footer_header_main_title {
        font-size: 1.5em;
    }

    .footer_header_sub_title {
        font-size: 0.7em;
    }

    .footer_first_title,
    .footer_first_desc,
    .footer_last_title,
    .footer_last_desc {
        max-width: 100%;
    }

    .footer_middle_logo {
        margin: 0 auto 20px;
    }

    .social_media_icons {
        color: var(--color-dark);
        width: 1em;
        height: 1em;
    }

    .footer_bottom_container {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .footer_header_right_section {
        flex-direction: column;
        align-items: center;
        padding: 15px 0 0;
    }

    .footer_section_title {
        font-size: 14px;
    }

    .app_button {
        margin-bottom: 0.3em;
        width: 12em;
        margin-right: 0;
    }

    .footer_header_logo {
        width: 3.5em;
        height: 3.5em;
    }

    .footer_header_main_title {
        font-size: 1.3em;
    }

    .footer_bottom_container {
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    .policy-title {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 576px) {
    .policy-container {
        padding: 2em;
    }

    .policy-title {
        font-size: 18px;
    }

    .policy-sub-title {
        font-size: 14px;
    }
}