.UserButton {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 0 40px 0 0;
    color: var(--color-white);
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    font-family: 'DMSans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    user-select: none;
    width: max-content;
    position: relative;
    transition: all 250ms ease-in-out;
}

.UserButton:hover {
    box-shadow: none !important;
}

.UserButton svg {
    position: absolute;
    right: 8px;
    top: 8px;
}

.UserButton--Active {
    box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 2px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.UserButton__FirstName {
    text-transform: capitalize;
    padding-left: 16px;
}

.notification_Icon_header {
    font-size: 24px;
    width: 24px;
    height: 24px;
}

.UserButton_ProfilePicture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.header-menu {
    position: absolute;
    right: -0.8rem;
    top: 100px;
    opacity: 0%;
    width: 100%;
    transition: all ease-in-out 250ms;
    z-index: 999;
    min-width: min-content;
    visibility: hidden;
}

.header-menu-active {
    opacity: 1;
    top: 60px;
    display: block;
    visibility: visible;
}

.UserButton__Menu {
    background-color: var(--color-white);
    border-radius: 10px;
    border: 2px solid var(--color-accent);
    color: var(--color-dark);
    text-align: left;
    font-weight: bold;
    font-size: 15px;
    width: 230px;
    padding: 15px;
    /* pointer-events: none; */
    user-select: none;
}

.UserButtonLogout {
    margin-top: 1rem;
}

.notification_count {
    display: flex;
    width: 22px;
    height: 17px;
    border-radius: 10px;
    background: var(--color-accent);
    justify-content: center;
    color: var(--color-white);
    font-size: 11px;
    align-items: center;
    position: absolute;
    left: 10px;
    top: -10px;
}

.UserButton__Menu ul li {
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
}

.UserButton__Menu ul li:nth-child(1) {
    padding-top: 5px;
}

.UserButton__Menu ul li:nth-last-child(1) {
    padding-bottom: 5px;
    border-bottom: none;
}

.menu-icon {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-icon svg {
    min-width: 24px;
    width: 24px;
    max-height: 24px;
    visibility: visible !important;
}

.UserButton__Menu ul li a {
    display: flex;
    align-items: center;
    pointer-events: all;
    gap: 15px;
    font-size: 14px;
    outline: none !important;
    box-shadow: none !important;
}

.UserButton__Container {
    width: max-content;
    position: relative;
}

.hr__UserMenu {
    border: 1px solid #b4b4b4;
    margin: 20px 0;
}

.UserButton__Menu__CloseWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}
@media only screen and (max-width: 1400px) {
    .UserButton {
        padding: 0 36px 0px 0;
    }
}
