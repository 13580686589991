.LoginMain {
    border-radius: 20px;
    border: 1px solid #b4b4b4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    width: 30%;
    min-width: 350px;
    margin: 4em 0 2em 0;
}

.LoginText {
    padding-bottom: 1em;
    font-size: 18px;
    width: 100%;
}

.LoginTextSmall {
    font-size: 14px;
}

.LoginHeader {
    color: var(--color-dark);
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 8px;
}

.LoginHeader--NoMargin {
    color: var(--color-dark);
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 5px;
}

.RetrieveLink {
    font-weight: bold;
    color: var(--color-dark);
}

.LoginInput {
    width: 100%;
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px solid var(--color-accent);
    outline: none;
    font-family: 'DMSans', sans-serif;
    font-weight: bold;
}

.LoginFormButton {
    padding: 10px 16px;
    width: 100%;
    background-color: var(--color-accent);
    font-size: 16px;
    color: var(--color-white);
    font-weight: bold;
    border-radius: 8px;
    border: none;
    outline: none;
}

.LoginFormButtonInverted {
    color: var(--color-accent);
    background-color: var(--color-white);
    border: 1px solid var(--color-accent);
}

@media only screen and (max-width: 495px) {
    .LoginHeader {
        font-size: 12px;
        margin-bottom: 4px;
    }
}
@media only screen and (max-width: 495px) {
    .LoginFormButton {
        padding: 0.5rem;
    }
}

@media only screen and (max-width: 1250px) {
    .LoginMain {
        width: 40%;
    }
}
@media only screen and (max-width: 1400px) {
    .LoginFormButton {
        font-size: 14px;
        font-weight: 500;
        padding: 9px 16px;
    }
    .LoginButton {
        padding: 8px 16px;
        font-size: 16px;
    }
    .LoginMain {
        padding: 1em;
        margin: 1.5em 0 2em 0;
    }
    .LoginHeader {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .LoginText {
        font-size: 14px;
    }
    .LoginInput {
        padding: 9px 15px;
        font-weight: normal;
        font-size: 14px;
        /* height: 40px; */
    }
}
@media only screen and (max-width: 576px) {
    .LoginHeader {
        font-size: 12px;
        margin-bottom: 1px;
    }
    .LoginMain {
        width: 92%;
    }
}
