.BookingItemCardContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    width: 250px;
    flex: 0 0 250px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    padding: 0.8em;
}

.BookingItemCardHeader {
    font-weight: bold;
    font-size: 16px;
}



.BookingItemPrice {
    font-size: 14px;
    color: var(--color-accent);
}

.BookingItemDetailsContainer {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
}

.BookingItemImage {
    height: 70px;
    width: 70px;
    margin-right: 15px;
    object-fit: cover;
}

@media only screen and (max-width: 1400px) {
    .BookingItemCardContainer {
        margin-bottom: 6px;
        margin-top: 6px;
    }
    .BookingItemPrice {
        font-size: 14px;
    }
    .BookingItemCardHeader {
        font-size: 14px;
    }
}
