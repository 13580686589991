.modal_title {
    color: var(--color-dark);
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    margin: 0;
    padding: 1em;
}

.modal_content {
    font-size: 14px;
    margin: 0;
    padding: 0 1em 1em;
}
.modal_content a {
    text-decoration: underline;
    color: var(--color-accent);
}

.modal_btn_container {
    display: flex;
    justify-content: center;
    padding: 1em;
}

.modal_loading,
.modal_btn_yes,
.modal_btn_no {
    width: 10rem;
    height: 2.5rem;
    padding: 0.5em;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: no-drop;
}


.modal_loading {
    background-color: #b43b4c;
    color: white;
    margin-right: 1rem;
}

.modal_btn_yes {
    color: white;
    border: none;
    outline: none;
    margin-right: 1rem;
    background-color: #b43b4c;
    transition: background-color 0.3s;
}

.modal_btn_yes[disabled] {
    background-color: #e1beb4;    /* Gray background for disabled state */
    cursor: not-allowed; /* Disabled cursor */
    pointer-events: none; /* Prevents click events */
}
.modal_btn_no {
    color: #b43b4c;
    border: 1px solid #b43b4c;
    outline: none;
}

.modal_btn_yes:hover {
    background-color: var(--color-accent);
}
