.UserShedWrapper {
    width: 100%;
    display: flex;
    margin: 1em 0;
    padding: 0 30px;
    min-height: 65vh;
}

.UserShed__Title {
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 30px;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid #ff89a67a;
}

.UserShed__MainContent {
    width: calc(100% - 280px);
}

@media screen and (max-width: 1400px) {
    .UserShed__Title {
        font-size: 24px;
        margin: 0.4rem;
    }
}

@media screen and (max-width: 1250px) {
    .UserShedWrapper {
        padding: 0 15px;
    }
}

@media screen and (max-width: 1080px) {
    .UserShed__Title {
        margin: 0 0 20px;
        font-size: 20px;
    }

    .UserShed__MainContent {
        width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .UserShedWrapper {
        flex-wrap: wrap;
    }

    .mob-not-menu {
        display: none;
    }

    .account-menu {
        display: flex;
        align-items: center;
        overflow-x: auto;
        margin-bottom: 8px;
    }

    .UserShedNav__SecondaryLink {
        white-space: nowrap;
        padding: 10px 16px;
        font-size: 16px;
    }

    .UserShedNav__SecondaryLink--active {
        border-bottom: 2px solid #ac172c;
    }

    .account-menu .HL {
        display: none;
    }

    .account-submenu .arrow {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .UserShedWrapper {
        padding: 0;
        min-height: auto;
    }
}

@media screen and (max-width: 576px) {
    .UserShed__Title {
        font-size: 18px;
        padding-bottom: 5px;
    }
}