.upload_item_image_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.upload_item_image_box {
    width: 47.5%;
    height: 12em;
    margin-bottom: 1em;
    position: relative;
}

.upload_item_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid var(--color-accent);
}