.TradeStatusDeclineButton {
    border: 1px solid var(--color-dark);
    background-color: white;
    border-radius: 10px;
    color: var(--color-dark);
    width: 45%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 0.3rem 0.1rem;
    margin-top: 0.5rem;
}

.TradeStatusApproveButton {
    border: 1px solid black;
    background-color: var(--color-dark);
    border-radius: 10px;
    color: #ededed;
    width: 45%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 0.3rem 0;
    margin-top: 0.5rem;
}

.TradeStatusButtonContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.TradeStatusContainer {
    padding: 25px 0;
    border-radius: 10px;
    background-color: #ededed;
}

.TradeDisputeContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
}

.TradeDisputeBtn {
    top: 586px;
    left: 742px;
    width: 438px;
    height: 50px;
    background: white;
    color: var(--color-accent);
    font-size: 1.1em;
    font-weight: 600;
    border: 1px solid #ac172c;
    border-radius: 10px;
    opacity: 1;
}

.TradeDisputeBtn:hover {
    box-shadow: none;
}

.TradeDisputeBtn:disabled {
    opacity: 0.4;
    cursor: default;
}

.TradeStatusContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    text-align: center;
}

.timeSetContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 1400px) {
    .TradeDisputeBtn {
        height: 39px;
        font-size: 14px;
    }
    .TradeDisputeContainer {
        padding-top: 0.5em;
    }
    .TradeStatusContentContainer {
        padding: 0 0.5rem;
        font-size: 14px;
    }
}
