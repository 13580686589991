.ActiveChatInputContainer {
    border: 1px solid var(--color-accent);
    width: 100%;
    border-radius: 10px;
    padding: 5px;
}

.chat-box-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-files {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid rgba(51, 56, 79, 0.4);
    padding-bottom: 5px;
    margin-bottom: 6px;
}

.send-message-timestamp{
    color: rgb(103, 101, 101);
    font-size: small;
    display: flex;
    justify-content: end;
 }

 .receive-message-timestamp{
    color: rgb(103, 101, 101);
    font-size: small;
    display: flex;
    justify-content: start;
 }

.ActiveChatInput {
    width: 100%;
    border: none;
    padding: 10px;
}

.ActiveChatInput:focus {
    outline-width: 0px;
}

.ActiveChatContainer {
    max-width: 700px;
    width: 100%;
    background-color: white;
}

.ActiveChatHeader {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 0 10px;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 10px;
}

.ActiveChatHeaderUser {
    display: flex;
    align-items: center;
}

.ActiveChatHeaderText {
    font-size: 22px;
    font-weight: bold;
    margin-left: 10px;
}

.ActiveChatHeaderRating {
    margin-left: 1rem;
    display: flex;
    align-items: flex-start;
}

.attchments-upload_item_image_box {
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ActiveChatMessageContainer .attchments-upload_item_image_box:nth-child(2) {
    margin: 40px 0 0 -80px;
}

.ActiveChatMessageContainer .attchments-upload_item_image_box:nth-child(3) {
    margin: 10px 0 0 -80px;
}

.ActiveChatMessageContainer .attchments-upload_item_image_box:nth-child(4) {
    margin: 60px 0 0 -80px;
}

.remove-attachment {
    position: absolute !important;
    top: 1px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: #e1e1e1 !important;
}
.attchments-upload_item_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid var(--color-accent);
}

.ActiveChatButton {
    background-color: var(--color-dark);
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ActiveChatMessageContainer {
    overflow-y: auto;
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
}
.image-error{
    width: 70%;
    background-color: rgb(240, 230, 217);
    color:  var(--color-accent);
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 3px 10px;
}
@media only screen and (max-width: 1400px) {
    .ActiveChatInputContainer {
        width: 98%;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 576px) {
    .ActiveChatContainer {
        position: absolute;
        width: 100%;
        padding: 0 15px;
    }
    .ActiveChatHeaderText {
        font-size: 14px;
    }
    .ActiveChatHeaderUser {
        margin-left: 0.5rem;
    }

    .ActiveChatInputContainer {
        width: 100%;
        align-self: center;
    }
}
