.DayDotsFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 20px;
    user-select: none;
}

.DotsFlex {
    display: flex;
    align-items: center;
}

.Capitalize {
    text-transform: capitalize;
}

.AvailabilityDot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #dedede;
    margin-right: 0.5em;
}

.DotMorningActive {
    background-color: var(--color-dark);
}

.DotAfternoonActive {
    background-color: var(--color-accent);
}

.DayInactive {
    color: #adadad;
    position: relative;
}

.DayActive {
    color: var(--color-dark);
}

.DayInactive:hover {
    cursor: pointer;
    color: var(--color-grey);
}

.triangle {
    position: absolute;
    background-color: var(--color-bg-light);
    text-align: left;
    left: 40%;
    top: 120%;
}
.triangle:before,
.triangle:after {
    content: '';
    position: absolute;
    background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
    width: 1em;
    height: 1em;
    border-top-right-radius: 30%;
}

.triangle {
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
.triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%);
}
.triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

@media only screen and (max-width: 1400px) {
    .DayDotsFlex {
        margin-bottom: 1em;
        font-size: 14px;
    }
    .AvailabilityDot {
        height: 16px;
        width: 16px;
    }
}
