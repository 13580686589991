.TradesContainer {
    width: calc(100% - 280px);
    flex: 0 0 calc(100% - 280px);
    padding: 0 10px 0 0;
    margin: 0 auto;
    /* display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%; */
}

.TradesContainer.sidebar-expand {
    width: calc(100% - 610px);
    flex: 0 0 610px;
}

.UserShed__MainContent {
    width: calc(100% - 280px);
}

.TradesPageWrapper {
    width: 100%;
    display: flex;
    margin: 2em 0;
    padding: 0 0 0 2%;
}

@media only screen and (max-width: 1400px) {
    .TradesContainer {
        width: calc(100% - 230px);
        flex: 0 0 calc(100% - 230px);
        padding: 0 10px 0 0;
    }
    .TradesContainer.sidebar-expand {
        width: calc(100% - 569px);
        flex: 0 0 569px;
    }
    .UserShed__MainContent {
        width: calc(100% - 224px);
    }
}
@media only screen and (max-width: 1080px) {
    .UserShed__MainContent {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .UserShed__MainContent {
        width: 100%;
        padding: 0 12px;
    }
    .TradesContainer {
        padding: 0 12px;
    }
}

@media only screen and (max-width: 495px) {
    .TradesContainer {
        width: 100%;
    }

    .TradesPageWrapper {
        margin: 0.5em 0;
    }
}
