.VerificationText__Main {
    background-color: var(--color-light);
    border-radius: 10px;
    padding: 0.7em;
    font-size: 18px;
    position: absolute;
    user-select: none;
    left: 106%;
    top: 0%;
    width: fit-content;
    max-width: 300px;
    opacity: 0;
}

.ValidationPopup__Container {
    position: relative;
}

.ValidationPopup__Header {
    color: var(--color-accent);
    font-weight: bold;
}

/* Triangle marker styles */

.triangleLeft {
    position: absolute;
    background-color: var(--color-light);
    text-align: left;
    left: 105%;
    top: 35%;
    opacity: 0;
}
.triangleLeft:before,
.triangleLeft:after {
    content: '';
    position: absolute;
    background-color: inherit;
}
.triangleLeft,
.triangleLeft:before,
.triangleLeft:after {
    width: 1em;
    height: 1em;
    border-top-right-radius: 30%;
}
.triangleLeft {
    transform: rotate(90deg) skewX(-30deg) scale(1, 0.866);
}
.triangleLeft:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%);
}
.triangleLeft:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

@media only screen and (max-width: 495px) {
    .VerificationText__Main {
        position: static;
        margin: 1rem 0;
    }
}
