.UserNotificationCardContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-right: 2rem;
}

.NotificationContentContainer {
    /* display: flex; */
    /* flex-direction: row;
  justify-content: center ; */
    width: 100%;
}

.containerParent {
    display: flex;
    justify-content: center;
    width: 100%;
}

.notification_container {
    border: 1px solid var(--color-accent);
    padding: 20px;
    border-radius: 10px;
    height: 530px;
    overflow: auto;
}

.notification_title {
    display: block;
    font-weight: 600;
    font-size: 18px;
}

.notification_body {
    display: block;
}

.notification_time {
    font-size: 12px;
}

.notificationCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 10px;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 10px;
    /* cursor: pointer; */
}

/* .notificationCard:nth-last-child(1) {
    padding: 0;
    border-bottom: none;
    margin-bottom: 0;
} */

.MessagesHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.notificationCard button.Button {
    padding: 6px 10px 5px;
    font-size: 15px;
    line-height: 18px;
    border-radius: 5px;
}

.notificationText {
    width: calc(100% - 120px);
    padding-right: 15px;
}
.notificationBtn-box {
    width: 120px;
}
@media only screen and (max-width: 1400px) {
    .MessagesHeading {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .notification_title {
        font-size: 18px;
        margin-bottom: 8px;
    }
    .notification_body {
        font-size: 14px;
        margin-bottom: 2px;
    }
}
@media only screen and (max-width: 767px) {
    .UserNotificationCardContainer {
        width: 100%;
        margin-right: 0;
    }
}
@media only screen and (max-width: 495px) {
    .UserCardContainer {
        width: 100%;
        margin-right: 0;
    }

    .notificationCard {
        flex-wrap: wrap;
    }

    .ContentContainer {
        justify-content: center;
    }
    .notificationText {
        width: 100%;
        padding: 0 0 10px 0;
    }
    .notificationBtn-box {
        display: flex;
        justify-content: flex-start;
    }
}
